<template>
  <main id="mainSlider">
    <div class="d-none">
      <button data-slide="prev" id="prevSlide"></button>
      <button data-slide="next" id="nextSlide"></button>
    </div>

    <div id="containerSlider">
      <div id="slider" class="box">
        <div class="slider-inner col-12 ml-0 ml-sm-5">
          <div id="slider-content" class="ml-0 ml-sm-5">
            <div
              id="slide-marque"
              v-bind:style="{ color: sliderInfos[0].titleColor }"
            >
              {{ sliderInfos[0].marque }}
            </div>
            <span
              v-for="(task, index) in sliderInfos"
              :key="task.marque + index"
              v-html="task.marque"
              :data-slide-marque="index"
            >
              {{ task.marque }}
            </span>

            <h2
              id="slide-titre"
              v-html="sliderInfos[0].titre"
              v-bind:style="{ color: sliderInfos[0].titleColor }"
            >
              {{ sliderInfos[0].titre }}
            </h2>
            <span
              v-for="(task, index) in sliderInfos"
              :key="task.titre + index"
              v-html="task.titre"
              :data-slide-titre="index"
              :data-color="task.textColor"
              :data-colorprevnext="task.prevNextColor"
            >
              {{ task.titre }}
            </span>

            <i
              id="slide-categorie"
              class="playfairdisplay"
              v-html="sliderInfos[0].status"
              v-bind:style="{ color: sliderInfos[0].titleColor }"
              >{{ sliderInfos[0].status }}</i
            >
            <span
              v-for="(task, index) in sliderInfos"
              :key="task.status + index"
              v-html="task.status"
              :data-slide-categorie="index"
            >
              {{ task.status }}
            </span>
          </div>
        </div>

        <img
          v-for="task in sliderInfos"
          :key="task.img"
          :src="task.img"
          alt=""
        />

        <div id="paginationNextPrev" class="d-none d-md-flex">
          <button data-slide="prev">
            <span>PRÉC</span>
            <div id="lol" class="arrow-left"></div>
          </button>
          <hr />
          <button data-slide="next">
            <span>SUIV</span>
            <div class="arrow-right"></div>
          </button>
        </div>
      </div>
    </div>

    <div
      id="slide-mini-video"
      v-on:click="greetVideo"
      @mouseenter="mouseEnter"
      @mouseleave="mouseLeave"
    >
      <div
        id="slide-mini-video-title"
        class="col-7"
        v-html="sliderInfos[0].videoTitle"
        :data-video="sliderInfos[0].video"
        :data-lien="sliderInfos[0].lien"
      >
        {{ sliderInfos[0].videoTitle }}
      </div>
      <span
        v-for="(task, index) in sliderInfos"
        :key="task.videoTitle + index"
        v-html="task.videoTitle"
        :data-slide-mini-video-title="index"
        :data-video="task.video"
        :data-lien="task.lien"
      >
        {{ task.videoTitle }}
      </span>
      <plyr ref="plyr" class="plyr--hover col-5">
        <video controls="false" loop />
      </plyr>

      <div class="slide-video-ico col-5">
        <span></span>
        <img src="../assets/images/icon/Icn_play.svg" />
      </div>
    </div>
  </main>
</template>

<script>
import plyr from "vue-plyr";
import * as THREE from "three";
import gsap from "gsap";
gsap.registerPlugin(Draggable, CSSRulePlugin);
import CSSRulePlugin from "gsap/CSSRulePlugin";
import Draggable from "gsap/Draggable";
import imagesLoaded from "imagesloaded";
export default {
  name: "Slider",
  components: {
    plyr,
  },
  data() {
    return {
      sliderInfos: [
        //{
        //  img: require("../assets/images/slider/patrick_bruel_2022.jpg"),
        //  textColor: "white",
        //  prevNextColor: "white",
        //  marque: "SONY MUSIC FRANCE",
        //  titre: "Réalisation des spots <br>Nouvel Album de Patrick Bruel",
        //  status: "Spots TV & Programme court TF1",
        //  videoTitle: "Découvrez le spot",
        //  video: "video",
        //  lien: "patrick_bruel_2022",
        //},
        //{
        //  img: require("../assets/images/slider/top_gun_2022.jpg"),
        //  textColor: "white",
        //  prevNextColor: "white",
        //  marque: "CGR CINEMAS / PARAMOUNT FRANCE",
        //  titre: "Production & réalisation<br>des spots Top Gun Maverick",
        //  status: "Spots TV & RS",
        //  videoTitle: "Découvrez les spots",
        //  video: "video",
        //  lien: "top_gun_2022",
        //},
        {
          img: require("../assets/images/slider/cncj_1.jpg"),
          textColor: "white",
          prevNextColor: "white",
          marque: "FRANCE TÉLÉVISIONS",
          titre:
            "Écriture, production & réalisation <br> pour les Commissaires de Justice <br> (CNCJ)",
          status: "2 spots génériques 15sec",
          videoTitle: "Découvrez la campagne",
          video: "video",
          lien: "cncj-bab-1",
        },

        {
          img: require("../assets/images/slider/cncj_2.jpg"),
          textColor: "white",
          prevNextColor: "white",
          marque: "FRANCE TÉLÉVISIONS",
          titre:
            "Écriture, production & réalisation <br> pour les Commissaires de Justice <br> (CNCJ)",
          status: "4 spots publi-reportages 40sec",
          videoTitle: "Découvrez la campagne",
          video: "video",
          lien: "cncj-bab-3-modif",
        },

        {
          img: require("../assets/images/slider/cnb_campagne_ftv_2022.jpg"),
          textColor: "white",
          prevNextColor: "white",
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Production & <br>réalisation pour le CNB",
          status: "33 spots TV & Web",
          videoTitle: "Découvrez la campagne",
          video: "video",
          lien: "cnb_campagne_ftv_2022",
        },
        {
          img: require("../assets/images/slider/interencheres_2022.jpg"),
          textColor: "black",
          prevNextColor: "black",
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Production & réalisation<br> pour Interenchères",
          status: "3 Billboards TV",
          videoTitle: "Découvrez la campagne",
          video: "video",
          lien: "interencheres_2022",
        },
        //{
        //  img: require("../assets/images/slider/abba_nopro_septembre_2_2021.jpg"),
        //  textColor: "white",
        //  prevNextColor: "white",
        //  marque: "",
        //  titre: "",
        //  status: "",
        //  videoTitle: "Découvrez la campagne",
        //  video: "video",
        //  lien: "abba_nopro_septembre_2021",
        //},
        //{
        //  img: require("../assets/images/slider/jacques_vabre-producteurs.jpg"),
        //  textColor: "white",
        //  prevNextColor: "white",
        //  marque: "FRANCE TÉLÉVISIONS",
        //  titre: "CAMPAGNE TV<br>Jacques Vabre",
        //  status: "Billboard",
        //  videoTitle: "Découvrez les billboards",
        //  video: "video",
        //  lien: "jacques_vabre-producteurs",
        //},
        //{
        //  img: require("../assets/images/slider/cgr_ba.jpg"),
        //  textColor: "white",
        //  prevNextColor: "white",
        //  marque: "CGR CINÉMAS",
        //  titre: "BANDE-ANNONCE",
        //  status: "",
        //  videoTitle: "Découvrez la BA",
        //  video: "video",
        //  lien: "cgr_ba",
        //},
        //{
        //  img: require("../assets/images/slider/mon_nom_est_johnny_decembre_2021.jpg"),
        //  textColor: "white",
        //  prevNextColor: "white",
        //  marque: "WARNER MUSIC FRANCE",
        //  titre: "FILM & LIVE ÉVÈNEMENT<br>Johnny Hallyday <br> Mon nom est Johnny",
        //  status: "Campagne TV & Réseaux sociaux",
        //  videoTitle: "Découvrez le spot",
        //  video: "video",
        //  lien: "mon_nom_est_johnny_decembre_2021",
        //},
        //{
        //  img: require("../assets/images/slider/rtl2_affiches.jpg"),
        //  textColor: "black",
        //  prevNextColor: "black",
        //  marque: "RTL2",
        //  titre: "CONCERTS<br/>TRÈS TRÈS PRIVÉS",
        //  status: "Spot TV",
        //  videoTitle: "Découvrez le spot",
        //  video: "video",
        //  lien: "rtl2_affiches",
        //},
        // {
        //  img: require('../assets/images/slider/imagine_dragons-coffret.jpg'),
        //  textColor: 'white',
        //  prevNextColor: 'white',
        //  marque: 'UNIVERSAL MUSIC',
        //  titre: 'IMAGINE DRAGONS <br> COFFRET INTÉGRALE',
        //  status: 'Spot TV',
        //  videoTitle: 'Découvrez le spot',
        //  'video': 'video',
        //  'lien': 'imagine_dragons-coffret'
        // },
        // {
        //  img: require('../assets/images/slider/alain_souchon-ame_fifties.jpg'),
        //  textColor: 'white',
        //  prevNextColor: 'white',
        //  marque: 'WARNER MUSIC',
        //  titre: 'ALAIN SOUCHON <br> ÂME FIFTIES',
        //  status: 'Spot TV',
        //  videoTitle: 'Découvrez le spot',
        //  'video': 'video',
        //  'lien': 'alain_souchon-ame_fifties'
        // },
      ],
    };
  },
  computed: {
    player() {
      return this.$refs.plyr.player;
    },
  },
  mounted() {
    // Slider
    const displacementSlider = function(opts) {
      let vertex = `
                    varying vec2 vUv;
                    void main() {
                        vUv = uv;
                        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                    }
                `;
      let fragment = `
                    varying vec2 vUv;
                    uniform sampler2D currentImage;
                    uniform sampler2D nextImage;
                    uniform float dispFactor;
                    void main() {
                        vec2 uv = vUv;
                        vec4 _currentImage;
                        vec4 _nextImage;
                        float intensity = 0.3;
                        vec4 orig1 = texture2D(currentImage, uv);
                        vec4 orig2 = texture2D(nextImage, uv);
                        _currentImage = texture2D(currentImage, vec2(uv.x, uv.y + dispFactor * (orig2 * intensity)));
                        _nextImage = texture2D(nextImage, vec2(uv.x, uv.y + (1.0 - dispFactor) * (orig1 * intensity)));
                        vec4 finalTexture = mix(_currentImage, _nextImage, dispFactor);
                        gl_FragColor = finalTexture;
                    }
                `;
      let images = opts.images,
        image,
        sliderImages = [];
      let canvasWidth = images[0].clientWidth;
      let canvasHeight = images[0].clientHeight;
      let parent = opts.parent;
      let renderWidth = window.innerWidth;
      let renderHeight = window.innerHeight;
      let renderW, renderH;
      renderH = window.innerHeight;
      renderW = (window.innerHeight * canvasWidth) / canvasHeight;
      if (window.innerWidth > renderW) {
        renderW = window.innerWidth;
        renderH = (window.innerWidth * canvasHeight) / canvasWidth;
      }
      let renderer = new THREE.WebGLRenderer({
        antialias: false,
      });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setClearColor(0x23272a, 1.0);
      renderer.setSize(renderW, renderH);
      parent.appendChild(renderer.domElement);
      let loader = new THREE.TextureLoader();
      loader.crossOrigin = "anonymous";
      images.forEach((img) => {
        image = loader.load(img.getAttribute("src") + "?v=" + Date.now());
        image.magFilter = image.minFilter = THREE.LinearFilter;
        image.anisotropy = renderer.capabilities.getMaxAnisotropy();
        sliderImages.push(image);
      });
      let scene = new THREE.Scene();
      scene.background = new THREE.Color(0x23272a);
      let camera = new THREE.OrthographicCamera(
        renderWidth / -2,
        renderWidth / 2,
        renderHeight / 2,
        renderHeight / -2,
        1,
        1000
      );
      camera.position.z = 1;
      let mat = new THREE.ShaderMaterial({
        uniforms: {
          dispFactor: { type: "f", value: 0.0 },
          currentImage: { type: "t", value: sliderImages[0] },
          nextImage: { type: "t", value: sliderImages[1] },
        },
        vertexShader: vertex,
        fragmentShader: fragment,
        transparent: true,
        opacity: 1.0,
      });
      let geometry = new THREE.PlaneBufferGeometry(
        parent.offsetWidth,
        parent.offsetHeight,
        1
      );
      let object = new THREE.Mesh(geometry, mat);
      object.position.set(0, 0, 0);
      scene.add(object);
      // init color Prev-Next
      let prevNextinitColor = document.querySelectorAll(
        `[data-slide-titre="${0}"]`
      )[0].dataset.colorprevnext;
      let ruleButtonColor = CSSRulePlugin.getRule("#paginationNextPrev button");
      gsap.to(ruleButtonColor, 0, { cssRule: { color: prevNextinitColor } });
      // trait du milieu
      let ruleLineColor = CSSRulePlugin.getRule("#paginationNextPrev hr");
      gsap.to(ruleLineColor, 0, {
        cssRule: { borderColor: prevNextinitColor },
      });
      // fleche barre
      let rulePrevColor = CSSRulePlugin.getRule(
        "#paginationNextPrev .arrow-left"
      );
      let ruleNextColor = CSSRulePlugin.getRule(
        "#paginationNextPrev .arrow-right"
      );
      gsap.to([rulePrevColor, ruleNextColor], 0, {
        cssRule: { background: prevNextinitColor },
      });
      // fleche prev
      let borderPrevAfterColor = CSSRulePlugin.getRule(
        "#paginationNextPrev .arrow-left::after"
      );
      gsap.to(borderPrevAfterColor, 0, {
        cssRule: { borderRightColor: prevNextinitColor },
      });
      // fleche next
      let borderNextAfterColor = CSSRulePlugin.getRule(
        "#paginationNextPrev .arrow-right::after"
      );
      gsap.to(borderNextAfterColor, 0, {
        cssRule: { borderLeftColor: prevNextinitColor },
      });
      // init Events
      let addEvents = function() {
        let pagPosition = 0;
        let pagMax = images.length;
        let pagButtons = Array.from(
          document.getElementById("mainSlider").querySelectorAll("button")
        );
        let isAnimating = false;
        pagButtons.forEach((el) => {
          el.addEventListener("click", function() {
            if (!isAnimating) {
              isAnimating = true;
              if (this.dataset.slide == "next") {
                pagPosition++;
                if (pagPosition == pagMax) pagPosition = 0;
              } else if (this.dataset.slide == "prev") {
                pagPosition--;
                if (pagPosition < 0) pagPosition = pagMax - 1;
              }
              let slideId = parseInt(pagPosition, 10);
              mat.uniforms.nextImage.value = sliderImages[slideId];
              mat.uniforms.nextImage.needsUpdate = true;
              gsap.to(mat.uniforms.dispFactor, 1, {
                value: 1,
                ease: "Expo.easeInOut",
                onComplete: function() {
                  mat.uniforms.currentImage.value = sliderImages[slideId];
                  mat.uniforms.currentImage.needsUpdate = true;
                  mat.uniforms.dispFactor.value = 0.0;
                  isAnimating = false;
                },
              });
              let slideMarqueEl = document.getElementById("slide-marque");
              let slideTitleEl = document.getElementById("slide-titre");
              let slideCategorieEl = document.getElementById("slide-categorie");
              let nextSlideMarque = document.querySelectorAll(
                `[data-slide-marque="${slideId}"]`
              )[0].innerHTML;
              let nextSlideTitle = document.querySelectorAll(
                `[data-slide-titre="${slideId}"]`
              )[0].innerHTML;
              let nextSlideCategorie = document.querySelectorAll(
                `[data-slide-categorie="${slideId}"]`
              )[0].innerHTML;
              let nextSlideColor = document.querySelectorAll(
                `[data-slide-titre="${slideId}"]`
              )[0].dataset.color;
              let slidepaginationPrevNextEl = document.getElementById(
                "paginationNextPrev"
              );
              let prevNextSlideColor = document.querySelectorAll(
                `[data-slide-titre="${slideId}"]`
              )[0].dataset.colorprevnext;
              let slideSlideMiniVideoEl = document.getElementById(
                "slide-mini-video"
              );
              let slideSlideMiniVideoTitleEl = document.getElementById(
                "slide-mini-video-title"
              );
              let nextSlideMiniVideoTitle = document.querySelectorAll(
                `[data-slide-mini-video-title="${slideId}"]`
              )[0].innerHTML;
              let videoMiniVideoTitle = document.querySelectorAll(
                `[data-slide-mini-video-title="${slideId}"]`
              )[0].dataset.video;
              let lienMiniVideoTitle = document.querySelectorAll(
                `[data-slide-mini-video-title="${slideId}"]`
              )[0].dataset.lien;
              gsap.fromTo(
                slidepaginationPrevNextEl,
                0.5,
                {
                  autoAlpha: 1,
                  filter: "blur(0px)",
                },
                {
                  autoAlpha: 0,
                  filter: "blur(10px)",
                  ease: "Expo.easeIn",
                  onComplete: function() {
                    // paginationNextPrev
                    // texte
                    let ruleButtonColor = CSSRulePlugin.getRule(
                      "#paginationNextPrev button"
                    );
                    gsap.to(ruleButtonColor, 0.5, {
                      cssRule: { color: prevNextSlideColor },
                    });
                    // trait du milieu
                    let ruleLineColor = CSSRulePlugin.getRule(
                      "#paginationNextPrev hr"
                    );
                    gsap.to(ruleLineColor, 0.5, {
                      cssRule: { borderColor: prevNextSlideColor },
                    });
                    // fleche barre
                    let rulePrevColor = CSSRulePlugin.getRule(
                      "#paginationNextPrev .arrow-left"
                    );
                    let ruleNextColor = CSSRulePlugin.getRule(
                      "#paginationNextPrev .arrow-right"
                    );
                    gsap.to([rulePrevColor, ruleNextColor], 0.5, {
                      cssRule: { background: prevNextSlideColor },
                    });
                    // fleche prev
                    let borderPrevAfterColor = CSSRulePlugin.getRule(
                      "#paginationNextPrev .arrow-left::after"
                    );
                    gsap.to(borderPrevAfterColor, 0.5, {
                      cssRule: { borderRightColor: prevNextSlideColor },
                    });
                    // fleche next
                    let borderNextAfterColor = CSSRulePlugin.getRule(
                      "#paginationNextPrev .arrow-right::after"
                    );
                    gsap.to(borderNextAfterColor, 0.5, {
                      cssRule: { borderLeftColor: prevNextSlideColor },
                    });
                    gsap.to(slidepaginationPrevNextEl, 0.5, {
                      autoAlpha: 1,
                      filter: "blur(0px)",
                    });
                  },
                }
              );
              gsap.fromTo(
                slideMarqueEl,
                0.5,
                {
                  autoAlpha: 1,
                  filter: "blur(0px)",
                  y: 0,
                },
                {
                  autoAlpha: 0,
                  filter: "blur(10px)",
                  y: 20,
                  ease: "Expo.easeIn",
                  onComplete: function() {
                    slideMarqueEl.innerHTML = nextSlideMarque;
                    let rule = CSSRulePlugin.getRule("#slide-marque:before");
                    gsap.to(rule, 0.5, {
                      cssRule: { background: nextSlideColor },
                    });
                    gsap.to(slideMarqueEl, 0.5, {
                      autoAlpha: 0.5,
                      color: nextSlideColor,
                      filter: "blur(0px)",
                      y: 0,
                    });
                  },
                }
              );
              gsap.fromTo(
                slideTitleEl,
                0.5,
                {
                  autoAlpha: 1,
                  filter: "blur(0px)",
                  y: 0,
                },
                {
                  autoAlpha: 0,
                  filter: "blur(10px)",
                  y: 20,
                  ease: "Expo.easeIn",
                  onComplete: function() {
                    slideTitleEl.innerHTML = nextSlideTitle;
                    gsap.to(slideTitleEl, 0.5, {
                      autoAlpha: 1,
                      color: nextSlideColor,
                      filter: "blur(0px)",
                      y: 0,
                      delay: 0.1,
                    });
                  },
                }
              );
              gsap.fromTo(
                slideCategorieEl,
                0.5,
                {
                  autoAlpha: 1,
                  filter: "blur(0px)",
                  y: 0,
                },
                {
                  autoAlpha: 0,
                  filter: "blur(10px)",
                  y: 20,
                  ease: "Expo.easeIn",
                  onComplete: function() {
                    slideCategorieEl.innerHTML = nextSlideCategorie;
                    gsap.to(slideCategorieEl, 0.5, {
                      autoAlpha: 1,
                      color: nextSlideColor,
                      filter: "blur(0px)",
                      y: 0,
                      delay: 0.2,
                    });
                  },
                }
              );
              gsap.fromTo(
                slideSlideMiniVideoEl,
                0.5,
                {
                  x: 0,
                },
                {
                  x: 400,
                  ease: "Expo.easeIn",
                  onComplete: function() {
                    // replace link / video
                    slideSlideMiniVideoTitleEl.dataset.video = videoMiniVideoTitle;
                    slideSlideMiniVideoTitleEl.dataset.lien = lienMiniVideoTitle;
                    // texte
                    slideSlideMiniVideoTitleEl.innerHTML = nextSlideMiniVideoTitle;
                    gsap.to(slideSlideMiniVideoEl, 0.5, {
                      x: 0,
                      delay: 0.1,
                      ease: "Circ.easeOut",
                    });
                  },
                }
              );
            }
          });
        });
      };
      addEvents();
      // eslint-disable-next-line
      window.addEventListener("resize", function(e) {
        renderH = window.innerHeight;
        renderW = (window.innerHeight * canvasWidth) / canvasHeight;
        if (window.innerWidth > renderW) {
          renderW = window.innerWidth;
          renderH = (window.innerWidth * canvasHeight) / canvasWidth;
        }
        renderer.setSize(renderW, renderH);
      });
      let animate = function() {
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      };
      animate();
    };
    imagesLoaded(document.querySelectorAll("img"), () => {
      document.body.classList.remove("loading");
      const el = document.getElementById("slider");
      const imgs = Array.from(el.querySelectorAll("img"));
      new displacementSlider({
        parent: el,
        images: imgs,
      });
    });
    // Drag
    // let draggables = Draggable.create(".box", {type:"x", edgeResistance:0.65, bounds:"#containerSlider", lockAxis:true, onDragEnd:endDrag  });
    let draggables = Draggable.create(".box", {
      type: "x",
      edgeResistance: 0.75,
      bounds: "#containerSlider",
      lockAxis: true,
      onDragEnd: endDrag,
    });
    //to get the first Draggable instance use
    let myDraggable = draggables[0];
    let windowWidth;
    window.addEventListener("resize", resizeWindows);
    resizeWindows();
    function resizeWindows() {
      windowWidth = window.innerWidth;
      if (windowWidth > 768) {
        myDraggable.enabled(false);
      } else {
        myDraggable.enabled(true);
      }
    }
    function endDrag() {
      gsap.from(".box", 1, { x: -this.deltaX });
      if (this.deltaX > (windowWidth * 100) / 662) {
        // drag left max
        document.getElementById("nextSlide").click();
      }
      if (this.deltaX < (-windowWidth * 100) / 662) {
        // drag right max
        document.getElementById("prevSlide").click();
      }
    }
  },
  methods: {
    greetVideo: function() {
      let slideSlideMiniVideoTitleEl = document.getElementById(
        "slide-mini-video-title"
      );
      let lien = slideSlideMiniVideoTitleEl.dataset.lien;
      if (slideSlideMiniVideoTitleEl.dataset.video == "video") {
        this.$root.$children[0].linkPlayer(lien);
      } else if (slideSlideMiniVideoTitleEl.dataset.video == "blank") {
        window.open(lien, "_blank");
      } else {
        window.location = lien;
      }
    },
    mouseEnter: function() {
      // console.log('mouseEnter');
      if (
        document.getElementById("slide-mini-video-title").dataset.video ==
        "video"
      ) {
        let logElem = document.querySelector(".plyr--hover");
        logElem.style.opacity = 1;
        let logElem2 = document.querySelector(".slide-video-ico");
        logElem2.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
        // source
        let lien = document.getElementById("slide-mini-video-title").dataset
          .lien;
        // source url
        this.player.source = {
          type: "video",
          title: lien,
          sources: [
            {
              src: "https://noproblemo.eu/media/slider/" + lien + ".mp4",
              type: "video/mp4",
            },
          ],
        };
        // lecture
        let playPromise = this.player.play();
        if (playPromise !== undefined) {
          // eslint-disable-next-line
          playPromise
            // eslint-disable-next-line
            .then((_) => {
              // Automatic playback started!
              // Show playing UI.
            })
            // eslint-disable-next-line
            .catch((error) => {
              // Auto-play was prevented
              // Show paused UI.
            });
        }
      }
    },
    mouseLeave: function() {
      // console.log('mouseLeave');
      let logElem = document.querySelector(".plyr--hover");
      logElem.style.opacity = 0;
      this.player.pause();
      let logElem2 = document.querySelector(".slide-video-ico");
      logElem2.style.backgroundColor = "rgba(179, 37, 114, 1)";
    },
  },
};
</script>

<style lang="scss">
#mainSlider {
  background-color: #23272a;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1;
  .plyr--hover {
    opacity: 0;
    transition: opacity 0.5s 0s;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    position: absolute;
    padding: 0;
    pointer-events: none;
  }
  .plyr__controls {
    display: none;
  }
}
#slide-marque {
  opacity: 0.5;
  &:before {
    content: "";
    display: inline-block;
    position: relative;
    vertical-align: middle;
    bottom: 2px;
    width: 2rem;
    height: 1px;
    background: white;
    margin-right: 0.4rem;
  }
  @include media-breakpoint-down(sm) {
    color: white !important;
    &:before {
      background: white !important;
    }
  }
}
#containerSlider {
  position: relative;
  height: 100%;
  z-index: 1;
}
#slider {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  canvas {
    width: 150%;
    height: 150%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    @include media-breakpoint-down(sm) {
      opacity: 0.4;
    }
  }
  img {
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 0;
  }
}
.slider-inner {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1546px;
  height: 100%;
  padding: 74px 0 150px 0;
  margin: 0 auto;
  z-index: 5;
}
#slider-content {
  padding: 0 10px;
  h2 {
    font-weight: 400;
    font-size: 2.5rem;
    color: white;
    line-height: 40px;
    @media screen and (max-width: 800px) {
      font-size: 2rem;
      line-height: 2rem;
    }
    @include media-breakpoint-down(sm) {
      color: white !important;
    }
  }
  span {
    display: none;
  }
  .meta {
    display: inline-block;
    font-family: "Arial", sans-serif;
    font-size: 11px;
    letter-spacing: 5px;
    color: #88888a;
    text-transform: uppercase;
    position: relative;
    @media screen and (min-width: 800px) {
      font-size: 13px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 5px;
      right: -55px;
      width: 45px;
      height: 2px;
      background-color: #393d40;
    }
  }
  #slide-categorie {
    display: block;
    font-size: 28px;
    color: white;
    @include media-breakpoint-down(sm) {
      color: white !important;
    }
  }
}
#paginationNextPrev {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  z-index: 6;
  span {
    font-size: 16px;
  }
  hr {
    width: 34px;
    background: transparent;
    border-color: white;
    border-width: 0 0 2px;
    border-style: solid;
  }
  .arrow-right {
    position: absolute;
    top: 16px;
    left: 6px;
    background: white;
    height: 2px;
    width: 37px;
    opacity: 0;
    &:after {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-left-color: white;
      border-width: 5px;
      margin-top: -5px;
    }
  }
  .arrow-left {
    position: absolute;
    top: 16px;
    right: 6px;
    background: white;
    height: 2px;
    width: 37px;
    opacity: 0;
    &:after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-right-color: white;
      border-width: 5px;
      margin-top: -5px;
    }
  }
  button {
    display: block;
    position: relative;
    color: white;
    background: none;
    border: none;
    appearance: none;
    &:hover {
      span {
        opacity: 0;
      }
      .arrow-left,
      .arrow-right {
        opacity: 1;
      }
    }
  }
}
#slide-mini-video {
  position: absolute;
  width: 374px;
  height: 150px;
  bottom: 0;
  right: 0;
  color: #131314;
  background-color: white;
  margin-bottom: 50px;
  cursor: pointer;
  z-index: 6;
  @media screen and (max-width: 420px) {
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: 0px;
  }
  span {
    display: none;
  }
  #slide-mini-video-title {
    display: inline-block;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .slide-video-ico {
    position: absolute;
    top: 0;
    right: 0;
    height: 150px;
    text-align: center;
    background-color: #b32572;
    overflow: hidden;
    transition: background-color 0.5s;
    span {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    img {
      vertical-align: middle;
      max-width: 60px;
    }
    video {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      position: absolute;
      opacity: 0;
      top: 0;
      right: 0;
      transition: opacity 0.5s, visibility 0s 0.5s;
    }
  }
}
/* Page Loader */
.loading {
  &:before {
    content: "";
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
  }
  &:after {
    content: "";
    position: fixed;
    z-index: 100000;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    pointer-events: none;
    border-radius: 50%;
    opacity: 0.4;
    background: white;
    animation: loaderAnim 0.7s linear infinite alternate forwards;
  }
}
@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}
</style>
