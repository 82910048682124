import Vue from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag';
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false;
Vue.use(VueGtag, {
    config: { id: "UA-60801962-1" }
}, router);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
