<template>
  <div id="footer" class="container-fluid">
      <div id="scrolldown" class="d-none d-lg-block" v-bind:class="{scrolldownOff: isActive}">
          SCROLL
          <div class="ligne_verticale mb-4"><span></span></div>
      </div>



      <div class="row h-100 align-items-center">
          <div class="col-12 text-center col-sm-6 text-sm-start align-self-end mb-3">
              © Noproblemo 2021
          </div>
          <div class="col-12 text-center align-self-start col-sm-6 text-sm-end align-self-sm-center">
              <a class="m-2 text-white" href="tel:+33142300000"><img src="../assets/images/icon/phone-alt.svg" alt="Téléphone"> +33 1 42 30 00 00</a>
              <a class="m-2" href="https://www.facebook.com/agence.noproblemo/" target="_blank"><img src="../assets/images/icon/facebook.svg" alt="Facebook"></a>
              <!-- <a class="m-2" href="" target="_blank"><img src="../assets/images/icon/youtube.svg" alt="YouTube"></a> -->
              <a class="m-2" href="https://www.instagram.com/agence_noproblemo/" target="_blank"><img src="../assets/images/icon/instagram.svg" alt="Instagram"></a>
          </div>
      </div>
    </div>
</template>

<script>
 export default {
    name: 'Footer',
     data() {
         return {
             isActive: false,
         }
     },
     methods: {
         handleScroll () {
             if(document.body.scrollHeight-200 > window.innerHeight) {
                 this.isActive = window.scrollY <= 50 ? false : true;
             } else {
                 this.isActive = true;
             }
         }
     },
     watch:{
       $route (){
           this.handleScroll();
       }
     },
     created () {
         window.addEventListener('scroll', this.handleScroll);
         window.addEventListener('resize', this.handleScroll);
     },
     destroyed () {
         window.removeEventListener('scroll', this.handleScroll);
         window.removeEventListener('resize', this.handleScroll);
     },
     mounted: function () {
         this.handleScroll();
     }
 }
</script>

<style scoped lang="scss">
    #footer{
        position: fixed;
        font-size: 12px;
        font-weight: 400;
        width: 100%;
        height: 50px;
        bottom: 0;
        color: white;
        background-color: #000000;
        z-index: 6;

        @media (max-width: 575px) {
            height: 150px;
            position: absolute;
            bottom: 0;
        }

        .active{
            display: none;
        }

        a:hover,a:active{
            text-decoration: none;
        }

        #scrolldown{
            pointer-events: none;
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            text-align: center;
            color: white;

            .ligne_verticale {
                margin: auto;
                width:1px;
                height:100px;
                background-color: rgba(255, 255, 255, 0.5);
                overflow: hidden;
            }

            .ligne_verticale span {
                display: block;
                width: 1px;
                height: 100px;
                background: white;
                animation-duration: 1.8s;
                animation-iteration-count: infinite;
                animation-name: scroll;
            }

            @keyframes scroll {
                0% {
                    transform: translateY(-100px);
                }
                70% {
                    transform: translateY(0);
                }
                100% {
                    transform: translateY(100px);
                }
            }
        }

        .scrolldownOff{
            opacity: 0;
            transition: opacity 0.5s;
        }
    }
</style>