import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/agence',
    name: 'agency',
    component: () => import(/* webpackChunkName: "about" */ '../views/Agency.vue')
  },
  {
      path: '/evenements',
          name: 'events',
          component: () => import('../views/events.vue'),
  },
  {
      path: '/creatifs',
          name: 'creatives',
      component: () => import('../views/Creatives.vue'),
  },
  {
      path: '/ftp',
          name: 'ftp',
      component: () => import('../views/Ftp.vue'),
  },
  {
      path: '/contact',
          name: 'contact',
      component: () => import('../views/Contact.vue'),
  },
  {
      path: '/mail',
          name: 'mail',
      component: () => import('../views/Mail.vue'),
  },
  {
      path: '/givenchy-gentleman',
          name: 'givenchygentleman',
      component: () => import('../views/GivenchyGentleman.vue'),
  },
  {
      path: '/givenchy-angenoir',
          name: 'givenchyangenoir',
      component: () => import('../views/GivenchyAngeNoir.vue'),
  },
  {
      path: '/404',
          name: '404',
      component: () => import('../views/404.vue'),
  },
  { path: '*', redirect: '/404' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
      return { y: 0 };
  },
})

export default router
