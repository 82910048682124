<template>
  <div id="app">
    <Header id="header"/>
    <thePlayer v-if="myplayer"/>
    <theSlideshow v-if="myslideshow"/>
    <router-view class="mb-5"/>
    <Footer/>
  </div>
</template>

<script>
    // @ is an alias to /src
    import thePlayer from '@/components/Player.vue'
    import theSlideshow from '@/components/SlideShow.vue'
    import Header from '@/components/Header.vue'
    import Footer from '@/components/Footer.vue'

    export default {
        name: 'app',
        data() {
            return {
                lien: 'noproblemo',
                myplayer: false,
                myslideshow: false,
            }
        },
        components: {
            thePlayer,
            theSlideshow,
            Header,
            Footer
        },
        methods: {
            linkPlayer: function(lienPlayer) {
                this.lien = lienPlayer;
                this.myplayer=true;
            },
            linkSlideshow: function(lienSlideshow) {
                this.lien = lienSlideshow;
                this.myslideshow=true;
            }
        },
        mounted: function () {
            let URLPlayer = this.$route.query.player;
            if(URLPlayer) {
                this.linkPlayer(URLPlayer);
            }
        },
    }
</script>

<style lang="scss">
  @import "../node_modules/bootstrap/scss/bootstrap";
  @import "./assets/scss/custom";

  #app{
    position: relative;
    min-height: 100vh;
    padding-bottom: 150px;
  }
</style>