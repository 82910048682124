<template>
  <div
    id="header"
    v-bind:class="{ headerOpenMenu: openMenu, scrolldownOn: isActive }"
  >
    <div class="containerMenu container-fluid mt-4 mb-4">
      <div class="row align-items-center">
        <div class="col-8 col-lg-4">
          <router-link to="/"
            ><img class="img-fluid" src="../assets/images/Logo.svg"
          /></router-link>
        </div>

        <div class="col-4 text-end d-lg-none">
          <a
            v-on:click="greetBurger"
            class="burger"
            v-bind:class="{ menu_active: openMenu }"
          >
            <span></span>
          </a>
        </div>

        <div
          class="col-12 col-lg-8 text-lg-end d-lg-block pt-5 pt-lg-0"
          :class="openMenu ? 'd-block' : 'd-none'"
        >
          <!--<div class="col-12 col-lg-8 text-lg-right d-none d-lg-block pt-5 pt-lg-0" v-bind:class="{ 'd-block': openMenu }">-->
          <div class="row text-center justify-content-lg-end">
            <div class="col-12 col-lg-auto">
              <a v-on:click="greetDemo">DÉMO</a>
            </div>
            <div class="col-12 col-lg-auto mt-4 mt-lg-0">
              <router-link to="/agence">AGENCE</router-link>
            </div>
            <!--<div class="col-12 col-lg-auto mt-4 mt-lg-0"><router-link to="/evenements">ÉVÉNEMENTS</router-link></div>-->
            <div class="col-12 col-lg-auto mt-4 mt-lg-0">
              <router-link to="/creatifs">CRÉATIFS</router-link>
            </div>
            <!--<div class="col-12 col-lg-auto mt-4 mt-lg-0"><router-link to="/ftp">FTP</router-link></div>-->
            <div class="col-12 col-lg-auto mt-4 mt-lg-0">
              <a href="https://nps-001.fr3.quickconnect.to/" target="_blank"
                >FTP</a
              >
            </div>
            <div class="col-12 col-lg-auto mt-4 mt-lg-0">
              <router-link to="/contact">CONTACT</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="containerWave d-lg-none">
      <div class="wavePink"></div>
      <div class="wave"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      openMenu: false,
      isActive: false,
    };
  },
  methods: {
    greetDemo: function() {
      this.$root.$children[0].linkPlayer("demo_nopro");
      this.openMenu = false;
    },
    greetBurger: function() {
      this.openMenu = !this.openMenu;

      let body = document.querySelector("body");
      if (this.openMenu) body.classList.add("overflowhidden");
      else body.classList.remove("overflowhidden");
    },
    handleScroll() {
      if (document.body.scrollHeight - 200 > window.innerHeight) {
        this.isActive = window.scrollY <= 50 ? false : true;
      } else {
        this.isActive = true;
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleScroll);
  },
  mounted: function() {
    this.handleScroll();
  },
  watch: {
    $route() {
      this.openMenu = false;
      // console.log(this.$route.name);
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  position: fixed;
  width: 100%;
  z-index: 10;

  &.scrolldownOn {
    background-color: hsla(240, 3%, 8%, 0.75);
    transition: background-color 0.5s linear;
  }

  &.active {
    background-color: black;
  }

  .containerMenu {
    position: relative;
    z-index: 1;
  }

  a {
    color: white;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  /* position wave init */
  .wavePink {
    top: -200%;
    transition: all 0ms linear;
  }

  .wave {
    top: -220%;
    transition: all 0ms linear;
  }
  /* ouverture */
  &.headerOpenMenu {
    .row.text-center {
      a:not(.router-link-active) {
        opacity: 0.7;
        animation: linkOpacity 2000ms linear;
      }

      a.router-link-active {
        text-decoration: underline;
        text-decoration-color: #b32572;
        animation: linkOpacity 2000ms linear;
      }

      @keyframes linkOpacity {
        from {
          opacity: 0;
        }
      }
    }

    @media screen and (max-width: 991px) {
      height: 100%;
      transition: background-color 2000ms linear;
      background-color: hsla(240, 3%, 8%, 1);
    }

    /* wave */
    .containerWave {
      width: 100%;
      height: 100%;
      min-width: 320px;
      min-height: 640px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 0;
      overflow: hidden;
    }

    .wavePink {
      width: 200%;
      height: 200%;
      min-width: 320px;
      min-height: 640px;
      position: absolute;
      top: -50%;
      left: -50%;
      border-radius: 35%;
      background: #b32572;
      transition: top 2000ms linear;
      animation: wavePink 4.5s 1 linear;

      visibility: hidden;
    }

    @keyframes wavePink {
      from {
        transform: rotate(0deg);
      }
      from {
        transform: rotate(360deg);
      }

      from {
        visibility: visible;
      }
      50% {
        visibility: hidden;
      }
    }

    .wave {
      width: 200%;
      height: 200%;
      min-width: 320px;
      min-height: 640px;
      position: absolute;
      top: -50%;
      left: -50%;
      border-radius: 35%;
      background: #131314;
      animation: wave 5s infinite linear;
      transition: top 2000ms linear;
    }

    @keyframes wave {
      from {
        transform: rotate(0deg);
      }
      from {
        transform: rotate(360deg);
      }
    }
  }
}

/* burger */
.burger {
  display: block;
  position: relative;
  float: right;
  width: 50px;
  height: 50px;
  background-color: #b32572;
  border-radius: 50%;

  span,
  span::before,
  span::after {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -10px;
    width: 20px;
    height: 2px;
    background-color: white;
  }

  span::before,
  span::after {
    content: "";
    display: block;
    transition: 0.2s;
  }

  span::before {
    transform: translateY(-5px);
  }

  span::after {
    transform: translateY(5px);
  }
}

.menu_active span {
  height: 0;
}

.menu_active span:before {
  transform: rotate(-45deg);
  transform-origin: center;
}
.menu_active span:after {
  transform: rotate(45deg);
  transform-origin: center;
}

/* ouverture */
</style>
