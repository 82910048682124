<template>
  <div class="home">
    <Slider/>
    <Works/>
  </div>
</template>

<script>
// @ is an alias to /src
import Slider from '@/components/Slider.vue'
import Works from '@/components/Works.vue'

export default {
  name: 'Home',
    data() {
        return {
            foo :2
        }
    },
  components: {
      Slider,
      Works,
  }
}
</script>
