<template>
    <div id="player">
        <plyr ref="plyr" class="plyr--video">
            <video crossorigin playsinline/>
        </plyr>
    </div>
</template>

<script>
import plyr from 'vue-plyr';

 export default {
     name: 'Player',
     components: {
         plyr,
     },
     mounted: function () {
         let playerMounted = this.player;
         let root = this.$parent;

         // indication problème de lecture
         this.player.on('error', event => {
             const instance = event.detail.plyr;
             console.log('error: '+instance);
         });

         // détection fin de lecture
         this.player.on('ended', event => {
             const instance = event.detail.plyr;
             console.log('ended: '+instance);
            
            // Fermeture
             closeVideo();

            // Boucle
            // this.player.play();
         });

         // debug
         this.player.on('debug', event => {
             const instance = event.detail.plyr;
             console.log('debug: '+instance);
         });

        // button close
        let buttonClose = document.createElement("button");
        buttonClose.setAttribute('class', 'buttonClose');
        buttonClose.innerHTML = "<div class='burger'><span></span></div>";

        let logElem = document.querySelector(".plyr");
        logElem.appendChild(buttonClose);

        buttonClose.addEventListener("click", closeVideo);

        function closeVideo() {
            buttonClose.removeEventListener("click", closeVideo);

            // Fermeture
            // playerMounted.toggleControls(false);
            playerMounted.destroy();
            playerMounted.off();
            root.myplayer=false;
        }

         // source
         let lien = this.$root.$children[0].lien;
         if(lien) {
             // source url
             this.player.source = {
               type: 'video',
               title: lien,
               sources: [
                   {
                       src: 'https://noproblemo.eu/media/'+lien+'.mp4',
                       type: 'video/mp4',
                       size: 720,
                   }
               ]
             };

             // lecture
             let playPromise = this.player.play();

             if (playPromise !== undefined) {
                 // eslint-disable-next-line
                 playPromise.then(_ => {
                     // Automatic playback started!
                     // Show playing UI.
                 })
                 // eslint-disable-next-line
                 .catch(error => {
                     // Auto-play was prevented
                     // Show paused UI.
                 });
             }

             // tracking
            //  this.$gtag.event('player', { method: lien });
         }
     },
     computed: {
         player () { 
             return this.$refs.plyr.player
         }
     }
 }
</script>

<style lang="scss" scoped>
    #player{
        display: block;
        position: fixed;
        color: white;
        width: 100%;
        height: 100vh;
        background-color: black;
        z-index: 10;
    }
</style>