<template>
  <div id="works" class="pt-5 pb-5">
    <div class="container-fluid" id="people">
      <div class="row">
        <div class="col-12 col-md-6 playfairdisplay text-32">
          Nos dernières réalisations
        </div>
        <div
          class="col-12 col-md-6 align-self-end filter d-flex justify-content-start justify-content-md-end"
        >
          <div
            id="searchIcon"
            v-bind:class="{ searchOpen: searchOpen }"
            v-on:click="searchShowHide"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              />
            </svg>
          </div>

          <div class="d-inline" v-for="task in menu" :key="task.menu">
            <input
              :id="task"
              type="radio"
              name="filtre"
              :value="task"
              v-on:click="selectSearchCheck"
              v-model="selectedCategory"
            /><label :for="task" class="radio">{{ task }}</label>
          </div>
        </div>

        <Search v-show="searchOpen" class="col-12 mt-3 mb-3" />

        <div class="col-12 mt-3 people-list">
          <div class="row grid">
            <figure
              class="col-12 col-sm-6 col-lg-4 col-xl-3 m-0 p-0 effect-ming"
              v-for="task in filteredContent"
              :key="task.lien"
              v-on:click="lien(task.video, task.lien)"
            >
              <img class="ratio" :src="task.image" :alt="task.titre" />

              <figcaption>
                <div class="task d-flex align-items-center h-100">
                  <div>
                    <h2 class="m-0" v-html="task.marque"></h2>
                    <h3 v-html="task.titre">{{ task.titre }}</h3>
                    <i class="playfairdisplay">{{ task.categorie }}</i>
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search.vue";
export default {
  name: "works",
  data() {
    return {
      menu: ["TOUT", "SPOT", "BILLBOARD", "DIGITAL"],
      people: [
        {
          image: require("../assets/images/homepage/r5-2024.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "R5 – Habillage Digital",
          categorie: "Spot tv display",
          video: "video",
          lien: "r5_display_30s_marche_velo",
          filtre: "SPOT,TV,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/saveurs-nutrition.jpg"),
          marque: "EDITIONS HUBERT BURDA",
          titre: "Saveurs Nutriton n°2",
          categorie: "Spot TV",
          video: "video",
          lien: "sn_2_15s",
          filtre: "SPOT,MAGAZINE,ÉDITION",
        },
        {
          image: require("../assets/images/homepage/fff.jpg"),
          marque: "ALIAS",
          titre: "FFF – Tournée 2024",
          categorie: "Spot tv",
          video: "video",
          lien: "fff_tournee_10s",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/rba-japon.jpg"),
          marque: "COLLECTIONS RBA",
          titre: "Mythes et légendes du Japon – Adaptation France",
          categorie: "Spot TV",
          video: "video",
          lien: "rba_japon_16s_web",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/polnareff-2024.jpg"),
          marque: "WARNER MUSIC FRANCE",
          titre: "Michel Polnareff – Nouvel album tournée",
          categorie: "Spot tv",
          video: "video",
          lien: "polnareff_live_15s_2024",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/adjani-2024.jpg"),
          marque: "WARNER MUSIC FRANCE",
          titre: "Isabelle Adjani – Nouvel album",
          categorie: "Spot tv",
          video: "video",
          lien: "adjani_30s_2024",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/mika-2024.jpg"),
          marque: "ALIAS",
          titre: "Mika - Tournée",
          categorie: "Spot tv",
          video: "video",
          lien: "mika_20s_2024",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/eddy-2024.jpg"),
          marque: "UNIVERSAL MUSIC FRANCE",
          titre: "Eddy Mitchell – Album de sa vie",
          categorie: "Spot tv",
          video: "video",
          lien: "mitchell_adsv_15s",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/jh-2024.jpg"),
          marque: "WARNER MUSIC FRANCE",
          titre: "Johnny Hallyday – Nouvel album",
          categorie: "Campagne TV & RS",
          video: "video",
          lien: "jh_tv_15s_2024",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/toolstation.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Toolstation – Sponsoring",
          categorie: "Billboard animation 2D/3D",
          video: "video",
          lien: "bab_bb_toolstation",
          filtre: "BILLBOARD,TV,GRAPHISME",
        },
        {
          image: require("../assets/images/homepage/mission-impossible-2024.jpg"),
          marque: "CGR CINEMAS",
          titre: "Production & réalisation des spots Mission Impossible",
          categorie: "Campagne TV & RS",
          video: "video",
          lien: "cgr_mission_impossible_30s_12_juillet",
          filtre: "SPOT,TV,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/calogero-2024.jpg"),
          marque: "UNIVERSAL MUSIC FRANCE",
          titre: "Calogero – Nouvel album",
          categorie: "Spot tv",
          video: "video",
          lien: "calogero_15s_2024",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/indiana-jones-cgr-2024.jpg"),
          marque: "CGR CINEMAS",
          titre: "Production & réalisation des spots Indiana Jones",
          categorie: "Campagne TV & RS",
          video: "video",
          lien: "cgr_indiana_jones_15s",
          filtre: "SPOT,TV,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/wlg-2024.jpg"),
          marque: "WE LOVE GREEN",
          titre: "Festival We Love Green – Promo ",
          categorie: "Spot tv",
          video: "video",
          lien: "wlg_20s_2024",
          filtre: "SPOT,TV,GRAPHISME",
        },
        {
          image: require("../assets/images/homepage/christophe-mae-2024.jpg"),
          marque: "WARNER MUSIC FRANCE",
          titre: "Christophe Maé – Nouvel album",
          categorie: "Spot tv",
          video: "video",
          lien: "mae_20s_2024",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/cic-2024.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "CIC – Victoires de la musique classique",
          categorie: "Billboard",
          video: "video",
          lien: "cic_victoires_8s_ba",
          filtre: "BILLBOARD,TV",
        },
        {
          image: require("../assets/images/homepage/indochine-2024.jpg"),
          marque: "SONY MUSIC FRANCE",
          titre: "Indochine – Central Tour le film",
          categorie: "Spot tv",
          video: "video",
          lien: "indo_ct_film_20s",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/the-weekend-2024.jpg"),
          marque: "UNIVERSAL MUSIC FRANCE",
          titre: "The Weeknd – Plateformes streaming",
          categorie: "Spot tv",
          video: "video",
          lien: "the_weeknd_30s",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/harry-styles-2024.jpg"),
          marque: "SONY MUSIC FRANCE",
          titre: "Harry Styles - Nouvel Album",
          categorie: "Spot tv",
          video: "video",
          lien: "harry_styles_15s",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/patrick-bruel-2024.jpg"),
          marque: "SONY MUSIC FRANCE",
          titre: "Patrick Bruel - Nouvel Album",
          categorie: "Spot tv",
          video: "video",
          lien: "patrick_bruel_2022",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/interencheres-2024.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Interenchères",
          categorie: "Billboard",
          video: "video",
          lien: "interencheres_2022",
          filtre: "BILLBOARD,TV",
        },
        {
          image: require("../assets/images/homepage/top_gun_2022.jpg"),
          marque: "CGR CINEMAS / PARAMOUNT FRANCE",
          titre: "Production & réalisation des spots Top Gun Maverick",
          categorie: "Campagne TV & RS",
          video: "video",
          lien: "top_gun_2022",
          filtre: "SPOT,TV,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/jacques_vabre-producteurs.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Jacques Vabre Producteurs",
          categorie: "Billboard",
          video: "video",
          lien: "jacques_vabre-producteurs",
          filtre: "BILLBOARD,3D,TV",
        },
        {
          image: require("../assets/images/homepage/cgr_sonic_20s_mars_2022.jpg"),
          marque: "CGR CINEMAS",
          titre: "Sonic 2 – Le film",
          categorie: "Campagne TV & RS",
          video: "video",
          lien: "cgr_sonic_20s_mars_2022",
          filtre: "SPOT,DIGITAL,TV",
        },
        {
          image: require("../assets/images/homepage/stromae_mars_2022.jpg"),
          marque: "UNIVERSAL MUSIC FRANCE",
          titre: "Stromae Nouvel Album",
          categorie: "Campagne TV & RS (France + Monde)",
          video: "video",
          lien: "stromae_mars_2022",
          filtre: "SPOT,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/mon_nom_est_johnny_decembre_2021.jpg"),
          marque: "WARNER MUSIC FRANCE",
          titre: "Film & Live Évènement - Mon nom est Johnny",
          categorie: "Campagne TV & Réseaux sociaux",
          video: "video",
          lien: "mon_nom_est_johnny_decembre_2021",
          filtre: "SPOT,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/angele_20s_decembre_2021.jpg"),
          marque: "UNIVERSAL MUSIC FRANCE",
          titre: "Angèle - Nouvel Album",
          categorie: "Spot tv",
          video: "video",
          lien: "angele_20s_decembre_2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/abba_nopro_septembre_2_2021.jpg"),
          marque: "UNIVERSAL MUSIC FRANCE",
          titre: "ABBA - Voyage",
          categorie: "Réseaux sociaux & Écrans digitaux",
          video: "video",
          lien: "abba_nopro_septembre_2021",
          filtre: "SPOT,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/rtl2_affiches.jpg"),
          marque: "RTL2",
          titre: "Concert Très Très Privés",
          categorie: "Spot TV",
          video: "video",
          lien: "rtl2_affiches",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/cabrel_20s_decembre_2021.jpg"),
          marque: "SONY MUSIC FRANCE",
          titre: "Francis Cabrel - Trobador Tour",
          categorie: "Spot TV & Programme Court",
          video: "video",
          lien: "cabrel_20s_decembre_2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/kyo_15s_novembre_2021.jpg"),
          marque: "SONY MUSIC FRANCE",
          titre: "Kyo - Nouvel Album",
          categorie: "Spot TV / radio & écrans digitaux",
          video: "video",
          lien: "kyo_15s_novembre_2021",
          filtre: "SPOT,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/cgr_ba.jpg"),
          marque: "CGR CINÉMAS",
          titre: "CGR Cinémas",
          categorie: "Spot, BA cinéma",
          video: "video",
          lien: "cgr_ba",
          filtre: "SPOT,BANDE ANNONCE,CINÉMA",
        },
        {
          image: require("../assets/images/homepage/sting_20s_novembre_2021.jpg"),
          marque: "UNIVERSAL MUSIC FRANCE",
          titre: "Sting - Nouvel Album The Bridge",
          categorie: "Spot TV & Réseaux sociaux Monde Entier",
          video: "video",
          lien: "sting_20s_novembre_2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/adele_20s_novembre_2021.jpg"),
          marque: "SONY MUSIC FRANCE",
          titre: "Adele - Nouvel Album 30",
          categorie: "Spot TV",
          video: "video",
          lien: "adele_20s_novembre_2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/asaf_avidan_10s_octobre_2021.jpg"),
          marque: "GÉRARD DROUOT PRODUCTIONS",
          titre: "Asaf Avidan Tournée",
          categorie: "Spot TV & Réseaux sociaux",
          video: "video",
          lien: "asaf_avidan_10s_octobre_2021",
          filtre: "SPOT,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/bb_jo_ouverture_juillet2021.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Générique Sponsors JO de Tokyo 2020",
          categorie: "Billboard",
          video: "video",
          lien: "bb_jo_ouverture_juillet2021",
          filtre: "BILLBOARD,GRAPHISME",
        },
        {
          image: require("../assets/images/homepage/soprano_20s_aout_2021.jpg"),
          marque: "REC 118",
          titre: "Soprano - Nouvel Album",
          categorie: "Spot TV",
          video: "video",
          lien: "soprano_20s_aout_2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/mylene_farmer_aout_2021.jpg"),
          marque: "UNIVERSAL MUSIC FRANCE",
          titre: "Mylène Farmer - Best Of",
          categorie: "Spot TV",
          video: "video",
          lien: "mylene_farmer_aout_2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/prince_15s_juillet2021.jpg"),
          marque: "SONY MUSIC",
          titre: "Prince - Nouvel album",
          categorie: "Spot TV",
          video: "video",
          lien: "prince_15s_juillet2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/rba_fantastique_juillet2021.jpg"),
          marque: "COLLECTIONS RBA",
          titre: "Les Maîtres du Fantastique",
          categorie: "Spot TV",
          video: "video",
          lien: "rba_fantastique_juillet2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/elton_john_juillet2021.jpg"),
          marque: "GÉRARD DROUOT PRODUCTIONS",
          titre: "Elton John - Tournée d'adieu",
          categorie: "Spot TV",
          video: "video",
          lien: "elton_john_juillet2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/harlem-globetrotters.jpg"),
          marque: "GÉRARD DROUOT PRODUCTIONS",
          titre: "Harlem Globetrotters",
          categorie: "Spot TV",
          video: "video",
          lien: "harlem_globetrotters_juillet2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/auto_journal10s.jpg"),
          marque: "REWORLD MEDIA",
          titre: "L'Auto-Journal",
          categorie: "Spot TV",
          video: "video",
          lien: "auto_journal10s",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/sting_rtl2_juin2021.jpg"),
          marque: "RTL2",
          titre: "Sting RTL2",
          categorie: "Spot TV, Web",
          video: "video",
          lien: "sting_rtl2_juin2021",
          filtre: "SPOT,DIGITAL,CORPORATE,ENTREPRISE",
        },
        {
          image: require("../assets/images/homepage/duncan_laurence_juin2021.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Duncan Laurence",
          categorie: "Spot TV",
          video: "video",
          lien: "duncan_laurence_20s_juin2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/maroon_5_juin2021.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Maroon 5",
          categorie: "Spot TV",
          video: "video",
          lien: "maroon_5_15s_juin2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/clara-luciani-juin-2021.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Clara Luciani",
          categorie: "Spot TV",
          video: "video",
          lien: "clara_luciani_20s_juin2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/entre-nous-juin-2021.jpg"),
          marque: "REWORLD MEDIA",
          titre: "Entre Nous",
          categorie: "Spot TV",
          video: "video",
          lien: "entre_nous_15s_juin2021",
          filtre: "SPOT,MAGAZINE,ÉDITION",
        },
        {
          image: require("../assets/images/homepage/keen-v-2021.jpg"),
          marque: "WARNER MUSIC",
          titre: "Keen'V",
          categorie: "Spot TV",
          video: "video",
          lien: "keen-v-2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/amaury_crescendo.jpg"),
          marque: "WARNER MUSIC",
          titre: "Amaury Vassilli",
          categorie: "Spot TV",
          video: "video",
          lien: "amaury_crescendo",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/bob_marley.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Bob Marley",
          categorie: "Spot TV",
          video: "video",
          lien: "bob_marley",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/boulevard-des-airs.jpg"),
          marque: "SONY MUSIC",
          titre: "Boulevard des airs",
          categorie: "Spot TV",
          video: "video",
          lien: "boulevard-des-airs",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/dr_good_vol23.jpg"),
          marque: "REWORLD MEDIA",
          titre: "Dr Good",
          categorie: "Spot TV",
          video: "video",
          lien: "dr_good_vol23",
          filtre: "SPOT,MAGAZINE,ÉDITION",
        },
        {
          image: require("../assets/images/homepage/moby_reprise.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Moby",
          categorie: "Spot TV",
          video: "video",
          lien: "moby_reprise",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/sting_duets.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Sting<br/>duets",
          categorie: "Spot TV",
          video: "video",
          lien: "sting_duets",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/fun_radio.jpg"),
          marque: "FUN RADIO",
          titre: "Fun Radio : le son Dancefloor",
          categorie: "Spot TV, accroche multi réseaux",
          video: "video",
          lien: "fun_radio",
          filtre: "SPOT,DIGITAL,CORPORATE,ENTREPRISE",
        },
        {
          image: require("../assets/images/homepage/lady_gaga_chromatica.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Lady Gaga",
          categorie: "Spot TV",
          video: "video",
          lien: "lady_gaga_chromatica",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/echappee_belle_alpes.jpg"),
          marque: "FRANCE TÉLÉVISION",
          titre: "Echappées Belles",
          categorie: "Spot TV",
          video: "video",
          lien: "echappee_belle_alpes",
          filtre: "SPOT,GRAPHISME",
        },
        {
          image: require("../assets/images/homepage/black_eyed_peas-nouvel_album.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Black Eyed Peas",
          categorie: "Spot TV",
          video: "video",
          lien: "black_eyed_peas-nouvel_album",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/ennion_morricone.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Ennio Morricone",
          categorie: "Spot TV",
          video: "video",
          lien: "ennion_morricone",
          filtre: "SPOT,GRAPHISME,MOTION DESIGN",
        },
        {
          image: require("../assets/images/homepage/detours_en_france-village_prefere_des_francais.jpg"),
          marque: "FRANCE TÉLÉVISION",
          titre: "Détours en France<br>Village préféré des français",
          categorie: "Spot TV",
          video: "video",
          lien: "detours_en_france-village_prefere_des_francais",
          filtre: "SPOT,GRAPHISME,MAGAZINE,EDITION",
        },
        {
          image: require("../assets/images/homepage/benjamin_biolay.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Benjamin Biolay",
          categorie: "Spot TV",
          video: "video",
          lien: "benjamin_biolay",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/gregory_porter.jpg"),
          marque: "RTL",
          titre: "Gregory porter",
          categorie: "Spot TV",
          video: "video",
          lien: "gregory_porter",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/lang_lang.jpg"),
          marque: "SONY MUSIC",
          titre: "Lang Lang",
          categorie: "Spot TV",
          video: "video",
          lien: "lang_lang",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/billboard_brisach.jpg"),
          marque: "VICTORIA",
          titre: "Brisach",
          categorie: "Billboard",
          video: "video",
          lien: "billboard_brisach",
          filtre: "BILLBOARD,GRAPHISME",
        },
        {
          image: require("../assets/images/homepage/indochine_singles_collection.jpg"),
          marque: "SONY MUSIC",
          titre: "Indochine",
          categorie: "Spot TV",
          video: "video",
          lien: "indochine_singles_collection",
          filtre: "SPOT,GRAPHISME",
        },
        {
          image: require("../assets/images/homepage/billboard_tenet_cgr.jpg"),
          marque: "CGR CINÉMAS",
          titre: "Tenet<br>CGR CINÉMAS",
          categorie: "Billboard",
          video: "video",
          lien: "billboard_tenet_cgr",
          filtre: "BILLBOARD,CINÉMA",
        },
        {
          image: require("../assets/images/homepage/julien_dore-nouvel_album.jpg"),
          marque: "SONY MUSIC",
          titre: "Julien Doré",
          categorie: "Spot TV",
          video: "video",
          lien: "julien_dore-nouvel_album",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/aaron.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Aaron",
          categorie: "Spot TV",
          video: "video",
          lien: "aaron",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/jean_baptiste_guegan.jpg"),
          marque: "SONY MUSIC",
          titre: "Jean-Baptiste Guégan",
          categorie: "Spot TV",
          video: "video",
          lien: "jean_baptiste_guegan",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/christophe_mae-vie_artiste.jpg"),
          marque: "WARNER MUSIC FRANCE",
          titre: "Christophe Maé",
          categorie: "Spot TV",
          video: "video",
          lien: "christophe_mae-vie_artiste",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/celine.jpg"),
          marque: "SONY MUSIC",
          titre: "Céline Dion",
          categorie: "Spot TV",
          video: "video",
          lien: "celine",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/clara_luciani.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Clara Luciani",
          categorie: "Spot TV",
          video: "video",
          lien: "clara_luciani",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/dj_snake.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "DJ Snake",
          categorie: "Spot TV",
          video: "video",
          lien: "dj_snake",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/ardoiz.jpg"),
          marque: "FRANCE TÉLÉVISION",
          titre: "Ardoiz",
          categorie: "Spot TV",
          video: "video",
          lien: "ardoiz",
          filtre: "SPOT,TOURNAGE",
        },
        {
          image: require("../assets/images/homepage/email_diamant-rose.jpg"),
          marque: "VICTORIA",
          titre: "Email Diamant",
          categorie: "Billboard",
          video: "video",
          lien: "email_diamant-rose",
          filtre: "BILLBOARD,GRAPHISME,3D",
        },
        {
          image: require("../assets/images/homepage/imagine_dragons-coffret.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Imagine Dragons Coffret Intégrale",
          categorie: "Spot TV",
          video: "video",
          lien: "imagine_dragons-coffret",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/funny_girl.jpg"),
          marque: "THÉATRE MARIGNY",
          titre: "Funny Girl",
          categorie: "Spot TV",
          video: "video",
          lien: "funny_girl",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/icebycgr-malefique.jpg"),
          marque: "CGR CINÉMAS",
          titre: "Maléfique",
          categorie: "Billboard",
          video: "video",
          lien: "icebycgr-malefique",
          filtre: "BILLBOARD,,CINÉMA",
        },
        {
          image: require("../assets/images/homepage/jl-aubert.jpg"),
          marque: "WARNER MUSIC",
          titre: "Jean-Louis Aubert",
          categorie: "Spot TV",
          video: "video",
          lien: "jl-aubert",
          filtre: "SPOT,GRAPHISME",
        },
        {
          image: require("../assets/images/homepage/johnny.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Johnny Hallyday",
          categorie: "Spot TV",
          video: "video",
          lien: "johnny",
          filtre: "SPOT,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/julien_clerc-duos.jpg"),
          marque: "WARNER MUSIC",
          titre: "Julien Clerc",
          categorie: "Spot TV",
          video: "video",
          lien: "julien_clerc-duos",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/alain_souchon-ame_fifties.jpg"),
          marque: "WARNER MUSIC",
          titre: "Alain Souchon Âme Fifties",
          categorie: "Spot TV",
          video: "video",
          lien: "alain_souchon-ame_fifties",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/laurent_voulzy-lv.jpg"),
          marque: "SONY MUSIC",
          titre: "Laurent Voulzy",
          categorie: "Spot TV",
          video: "video",
          lien: "laurent_voulzy-lv",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/le_grand_bleu.jpg"),
          marque: "GÉRARD DROUOT PRODUCTION",
          titre: "Le Grand Bleu",
          categorie: "Spot TV",
          video: "video",
          lien: "le_grand_bleu",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/les_vieilles_canailles-concert.jpg"),
          marque: "WARNER MUSIC",
          titre: "Les Vieilles Canailles",
          categorie: "Spot TV",
          video: "video",
          lien: "les_vieilles_canailles-concert",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/m_pokora-pyramide_tour.jpg"),
          marque: "ARACHNÉE PRODUCTIONS",
          titre: "M. Pokora",
          categorie: "Spot TV",
          video: "video",
          lien: "m_pokora-pyramide_tour",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/lor_olympique_6s.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "L'Or",
          categorie: "Billboard",
          video: "video",
          lien: "lor_olympique_6s",
          filtre: "BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/13h-villages.jpg"),
          marque: "TF1",
          titre: "Sos Villages",
          categorie: "Opération digitale, Web",
          video: "video",
          lien: "13h-villages",
          filtre: "DIGITAL,SITE INTERNET,DÉVELOPPEMENT",
        },
        {
          image: require("../assets/images/homepage/filorga_box_noel.jpg"),
          marque: "FILORGA",
          titre: "Coffret Filorga x Ora Ito",
          categorie: "Digitale, Web",
          video: "video",
          lien: "filorga_box_noel",
          filtre: "DIGITAL,3D,LUXE,FILM TUTORIEL,VIDÉO TUTORIEL",
        },
        {
          image: require("../assets/images/homepage/alexandre_tharaud.jpg"),
          marque: "WARNER MUSIC",
          titre: "Alexandre Tharaud",
          categorie: "Spot TV",
          video: "video",
          lien: "alexandre_tharaud",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/stomp.jpg"),
          marque: "GÉRARD DROUOT PRODUCTIONS",
          titre: "Stomp",
          categorie: "Spot TV",
          video: "video",
          lien: "stomp",
          filtre: "SPOT,TV",
        },
        {
          image: require("../assets/images/homepage/aya_nakamura-nouvelle_edition.jpg"),
          marque: "WARNER MUSIC",
          titre: "Aya Nakamura",
          categorie: "Spot TV",
          video: "video",
          lien: "aya_nakamura-nouvelle_edition",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/lion.jpg"),
          marque: "MCCAN ERICKSON",
          titre: "Lion Wild",
          categorie: "Spot digital",
          video: "video",
          lien: "lion",
          filtre: "DIGITAL,TOURNAGE",
        },
        {
          image: require("../assets/images/homepage/billie_eilish.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Billie Eilish",
          categorie: "Spot TV",
          video: "video",
          lien: "billie_eilish",
          filtre: "SPOT,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/bosh_variostyle_12s.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Bosch Variostyle",
          categorie: "Billboard",
          video: "video",
          lien: "bosch_variostyle_12s",
          filtre: "BILLBOARD,3D",
        },
        {
          image: require("../assets/images/homepage/nerf_snapchat.jpg"),
          marque: "FUSE",
          titre: "Nerf Rival",
          categorie: "Snap ad Snapchat",
          video: "video",
          lien: "nerf_snapchat",
          filtre: "DIGITAL,GRAPHISME,COMMUNICATION",
        },
        {
          image: require("../assets/images/homepage/peugeot_43s.jpg"),
          marque: "MCCANN",
          titre: "Peugeot Belville",
          categorie: "Film Digital",
          video: "video",
          lien: "peugeot_43s",
          filtre: "DIGITAL,TOURNAGE",
        },
        {
          image: require("../assets/images/homepage/rungis_market_30s.jpg"),
          marque: "MCCANN",
          titre: "Rungis Market",
          categorie: "Spot TV",
          video: "video",
          lien: "rungis_market_30s",
          filtre: "SPOT,TOURNAGE,CRÉATION",
        },
        {
          image: require("../assets/images/homepage/liberon_12s.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Libéron",
          categorie: "Billboard",
          video: "video",
          lien: "liberon_12s",
          filtre: "BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/bb_st_hubert_creme.jpg"),
          marque: "STUDIO M",
          titre: "St Hubert",
          categorie: "Billboard",
          video: "video",
          lien: "bb_st_hubert_creme",
          filtre: "BILLBOARD,3D",
        },
        {
          image: require("../assets/images/homepage/catq_web.jpg"),
          marque: "BECAUSE MUSIC",
          titre: "Christine and the Queen",
          categorie: "Activation digitale",
          video: "video",
          lien: "catq_web",
          filtre: "DIGITAL",
        },
        {
          image: require("../assets/images/homepage/tcn.jpg"),
          marque: "TOURAINE CHER NUMÉRIQUE",
          titre: "Déploiement numérique",
          categorie: "Film motion design",
          video: "video",
          lien: "tcn",
          filtre: "SPOT,MOTION DESIGN,GRAPHISME,ANIMATION,2D",
        },
        {
          image: require("../assets/images/homepage/bb_dodo_7s_chuchote.jpg"),
          marque: "VICTORIA",
          titre: "Dodo",
          categorie: "Billboard",
          video: "video",
          lien: "bb_dodo_7s_chuchote",
          filtre: "BILLBOARD,TOURNAGE",
        },
        {
          image: require("../assets/images/homepage/votre_plus_beau_marche.jpg"),
          marque: "TF1",
          titre: "Votre Plus Beau Marché",
          categorie: "Opération digitale, Web",
          video: "video",
          lien: "votre_plus_beau_marche",
          filtre: "DIGITAL,SITE INTERNET,DÉVELOPPEMENT",
        },
        {
          image: require("../assets/images/homepage/majestic_barriere.jpg"),
          marque: "GROUPE LUCIEN BARRIÈRE",
          titre: "Hôtel Barrière Le Majestic Cannes",
          categorie: "Publicité",
          video: "video",
          lien: "majestic_barriere",
          filtre: "PUBLICITÉ,TOURNAGE",
        },
        {
          image: require("../assets/images/homepage/barriereneige.jpg"),
          marque: "GROUPE LUCIEN BARRIÈRE",
          titre: "Barrière Les Neiges",
          categorie: "Film marque",
          video: "video",
          lien: "barriereneige",
          filtre: "PUBLICITÉ,3D,FILM MARQUE,LUXE",
        },
        {
          image: require("../assets/images/homepage/secret_histoire_vol24.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Secret D'Histoire",
          categorie: "Spot TV",
          video: "video",
          lien: "secret_histoire_vol24",
          filtre: "SPOT,GRAPHISME,3D,MAGAZINE,ÉDITION,MAGAZINE,ÉDITION",
        },
        {
          image: require("../assets/images/homepage/crazy_horse.jpg"),
          marque: "CRAZY HORSE",
          titre: "Bionic Showgirl",
          categorie: "Digital Content, Accroche multi réseaux",
          video: "video",
          lien: "crazy_horse",
          filtre: "DIGITAL,GRAPHISME,MOTION DESIGN",
        },
        {
          image: require("../assets/images/homepage/fouquets.jpg"),
          marque: "FOUQUET'S",
          titre: "Collection Olfactive Fouquet's",
          categorie: "Film produit",
          video: "video",
          lien: "fouquets",
          filtre: "MOTION DESIGN,2D",
        },
        {
          image: require("../assets/images/homepage/la_poste_vsmp_2019.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "La Poste : Veiller sur mes parents 2019",
          categorie: "Spot TV",
          video: "video",
          lien: "la_poste_vsmp_2019",
          filtre: "SPOT,TOURNAGE",
        },
        {
          image: require("../assets/images/homepage/jacques_vabre-bahia.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Jacques Vabre Bahia",
          categorie: "Billboard",
          video: "video",
          lien: "jacques_vabre-bahia",
          filtre: "BILLBOARD,3D,TV",
        },
        {
          image: require("../assets/images/homepage/axa.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Axa",
          categorie: "Programme court",
          video: "video",
          lien: "axa",
          filtre: "BILLBOARD,CRÉATION",
        },
        {
          image: require("../assets/images/homepage/hr.jpg"),
          marque: "HELENA RUBINSTEIN",
          titre: "HR",
          categorie: "Film marque",
          video: "video",
          lien: "hr",
          filtre: "FILM MARQUE,LUXE",
        },
        {
          image: require("../assets/images/homepage/angele-laydee.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Angèle",
          categorie: "Spot TV",
          video: "video",
          lien: "angele-laydee",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/givenchy_gentleman.jpg"),
          marque: "GIVENCHY",
          titre: "Gentleman",
          categorie: "Givenchy, stratégie digitale et création de contenu",
          video: "",
          lien: "/givenchy-gentleman",
          filtre:
            "GRAPHISME,COMMUNICATION,DIGITAL,MOTION DESIGN,TOURNAGE,CRÉATION,LUXE",
        },
        {
          image: require("../assets/images/homepage/encore-un-instant.jpg"),
          marque: "THÉATRE ÉDOUARD VII",
          titre: "Encore un instant",
          categorie: "Campagne 360 (TV, web & radio)",
          video: "video",
          lien: "encore-un-instant",
          filtre: "TOURNAGE,ENTERTAINMENT,ENTERTAINMENT",
        },
        {
          image: require("../assets/images/homepage/crazy_love_week.jpg"),
          marque: "CRAZY HORSE",
          titre: "Crazy Love Week",
          categorie: "Digital Content, Accroche multi réseaux",
          video: "video",
          lien: "crazy_love_week",
          filtre: "DIGITAL,GRAPHISME,MOTION DESIGN,TOURNAGE",
        },
        {
          image: require("../assets/images/homepage/allianz.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Alliance",
          categorie: "Billboard TV - tournage",
          video: "video",
          lien: "allianz",
          filtre: "BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/renault_zoe.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Renault Zoé",
          categorie: "Habillage écran pub",
          video: "video",
          lien: "renault",
          filtre: "PUBLICITÉ",
        },
        {
          image: require("../assets/images/homepage/renaud_phenix.jpg"),
          marque: "WARNER MUSIC",
          titre: "Renaud Phénix",
          categorie: "Spot TV",
          video: "video",
          lien: "renaud-phenix_20s",
          filtre: "SPOT,GRAPHISME,ANIMATION",
        },
        {
          image: require("../assets/images/homepage/martin_et_les_fees.jpg"),
          marque: "SONY MUSIC",
          titre: "Martin et les Fées",
          categorie: "Clip",
          video: "video",
          lien: "martin_et_les_fees",
          filtre: "MOTION DESIGN,ANIMATION,2D",
        },
        {
          image: require("../assets/images/homepage/brut.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Brut",
          categorie: "Billboard TV - tournage",
          video: "video",
          lien: "brut",
          filtre: "BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/desjoyaux.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Desjoyaux",
          categorie: "Billboard TV",
          video: "video",
          lien: "desjoyaux",
          filtre: "3D,BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/airwaves.jpg"),
          marque: "OMD",
          titre: "Airwaves",
          categorie: "Billboard TV",
          video: "video",
          lien: "airwaves",
          filtre: "3D,BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/macif.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Macif",
          categorie: "Programme court",
          video: "video",
          lien: "macif-sur_la_route-ep01",
          filtre: "TOURNAGE,PROGRAMME COURT",
        },
        {
          image: require("../assets/images/homepage/mylene_timeless.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Mylène Farmer - Timeless",
          categorie: "Spot TV",
          video: "video",
          lien: "mylene_timeless",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/callas.jpg"),
          marque: "WARNER MUSIC",
          titre: "Maria Callas",
          categorie: "Spot TV",
          video: "video",
          lien: "callas",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/enfoires_2021.jpg"),
          marque: "SONY MUSIC",
          titre: "Les Enfoirés 2021",
          categorie: "Spot TV",
          video: "video",
          lien: "enfoires_2021",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/bal-des-vampires.jpg"),
          marque: "STAGE ENTERTAINMENT FRANCE",
          titre: "Bal des vampires",
          categorie: "Spot TV",
          video: "video",
          lien: "bal-des-vampires",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/pink-lady.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Pink Lady",
          categorie: "Spot TV",
          video: "video",
          lien: "pink-lady",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/un-petit-jeu-sans-consequence.jpg"),
          marque: "CALT PRODUCTION",
          titre: "Un petit jeu sans conséquence",
          categorie: "Spot TV",
          video: "video",
          lien: "un-petit-jeu-sans-consequence",
          filtre: "SPOT,ENTERTAINMENT",
        },
        {
          image: require("../assets/images/homepage/rtl2-spot.jpg"),
          marque: "RTL2",
          titre: "Spot RTL2",
          categorie: "Spot TV",
          video: "video",
          lien: "rtl2-spot",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/gad-elmaleh.jpg"),
          marque: "TF1",
          titre: "Gad Elmaleh",
          categorie: "Spot TV",
          video: "video",
          lien: "gad-elmaleh",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/madonna-rebel_heart.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Madonna Rebel Heart",
          categorie: "Spot TV",
          video: "video",
          lien: "madonna-rebel_heart",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/maroon5.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Maroon 5",
          categorie: "Spot TV",
          video: "video",
          lien: "maroon5",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/thomas-dutronc.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Thomas Dutronc",
          categorie: "Spot TV",
          video: "video",
          lien: "thomas-dutronc",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/mika.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Mika",
          categorie: "Spot TV",
          video: "video",
          lien: "mika",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/fifty-shades-of-grey.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Fifty Shades of Grey B.O.",
          categorie: "Spot TV",
          video: "video",
          lien: "fifty-shades-of-grey",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/telephone-tribute.jpg"),
          marque: "WARNER MUSIC",
          titre: "Téléphone Tribute",
          categorie: "Spot TV",
          video: "video",
          lien: "telephone-tribute",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/cats_spot.jpg"),
          marque: "STAGE ENTERTAINMENT FRANCE",
          titre: "Cat's",
          categorie: "Spot TV",
          video: "video",
          lien: "cats_spot",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/zazie.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "RTL2 Zazie",
          categorie: "Spot TV",
          video: "video",
          lien: "zazie",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/comedie_musical-jules_verne.jpg"),
          marque: "VANTAGE PROD",
          titre: "Comédie Musicale Jules Vernes",
          categorie: "Spot TV",
          video: "video",
          lien: "comedie_musical-jules_verne",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/corto-maltese.jpg"),
          marque: "CASTERMAN",
          titre: "Corto Maltese",
          categorie: "Spot TV",
          video: "video",
          lien: "corto-maltese",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/josef-salvat.jpg"),
          marque: "SONY MUSIC",
          titre: "Josef Salvat",
          categorie: "Spot TV",
          video: "video",
          lien: "josef-salvat",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/celine-accor-hotels-arena.jpg"),
          marque: "AEG PRESENTS",
          titre: "Céline Dion AccorHotels Arena",
          categorie: "Spot TV",
          video: "video",
          lien: "celine-accor-hotels-arena",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/jeff-panacloc.jpg"),
          marque: "TF1",
          titre: "Jeff Panacloc",
          categorie: "Spot TV",
          video: "video",
          lien: "jeff-panacloc",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/david-guetta-listen-again.jpg"),
          marque: "WARNER MUSIC",
          titre: "David Guetta",
          categorie: "Spot TV",
          video: "video",
          lien: "david-guetta-listen-again",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/brigitte.jpg"),
          marque: "SONY MUSIC",
          titre: "Brigitte",
          categorie: "Spot TV",
          video: "video",
          lien: "brigitte",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/adele.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Adele",
          categorie: "Spot TV",
          video: "video",
          lien: "adele",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/madame-foresti.jpg"),
          marque: "TF1",
          titre: "Florence Foresti - Spectacle Madame Foresti",
          categorie: "Spot TV",
          video: "video",
          lien: "madame-foresti",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/stromae.jpg"),
          marque: "SONY MUSIC",
          titre: "Stromae",
          categorie: "Spot TV",
          video: "video",
          lien: "stromae",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/de-palmas.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "De Palmas",
          categorie: "Spot TV",
          video: "video",
          lien: "de-palmas",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/l-invite.jpg"),
          marque: "CALT PRODUCTION",
          titre: "L'invité",
          categorie: "Spot TV",
          video: "video",
          lien: "l-invite",
          filtre: "SPOT,ENTERTAINMENT",
        },
        {
          image: require("../assets/images/homepage/florent-pagny.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Florent Pagny",
          categorie: "Spot TV",
          video: "video",
          lien: "florent-pagny",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/sia.jpg"),
          marque: "SONY MUSIC",
          titre: "Sia",
          categorie: "Spot TV",
          video: "video",
          lien: "sia",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/pascal-obispo.jpg"),
          marque: "SONY MUSIC",
          titre: "Pascal Obispo",
          categorie: "Spot TV",
          video: "video",
          lien: "pascal-obispo",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/zayn.jpg"),
          marque: "SONY MUSIC",
          titre: "Zayn",
          categorie: "Spot TV",
          video: "video",
          lien: "zayn",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/le-plus-beau-jour.jpg"),
          marque: "CALT PRODUCTION",
          titre: "Le plus beau jour",
          categorie: "Spot TV",
          video: "video",
          lien: "le-plus-beau-jour",
          filtre: "SPOT,ENTERTAINMENT",
        },
        {
          image: require("../assets/images/homepage/la-banque-postale.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "La Banque Postale",
          categorie: "Spot TV",
          video: "video",
          lien: "la-banque-postale",
          filtre: "BILLBOARD,TV",
        },
        {
          image: require("../assets/images/homepage/marionnaud.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Marionnaud",
          categorie: "Billboard TV",
          video: "video",
          lien: "marionnaud",
          filtre: "BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/caisse-d-epargne.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Caisse d'Epargne",
          categorie: "Billboard TV",
          video: "video",
          lien: "caisse-d-epargne",
          filtre: "BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/digster.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Digster",
          categorie: "Billboard TV",
          video: "video",
          lien: "digster",
          filtre: "BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/pixels.jpg"),
          marque: "OMD",
          titre: "Pixels",
          categorie: "Billboard TV",
          video: "video",
          lien: "pixels",
          filtre: "BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/aoste.jpg"),
          marque: "MCCANN",
          titre: "Aoste",
          categorie: "Billboard TV",
          video: "video",
          lien: "aoste",
          filtre: "BILLBOARD,",
        },
        {
          image: require("../assets/images/homepage/chair-de-poule.jpg"),
          marque: "OMD",
          titre: "Chair de Poule - Le film",
          categorie: "Billboard TV",
          video: "video",
          lien: "chair-de-poule",
          filtre: "BILLBOARD,",
        },
        {
          image: require("../assets/images/homepage/cats-teaser.jpg"),
          marque: "STAGE ENTERTAINMENT FRANCE",
          titre: "Cat's Teaser",
          categorie: "Billboard TV",
          video: "video",
          lien: "cats-teaser",
          filtre: "BILLBOARD, DIGITAL",
        },
        {
          image: require("../assets/images/homepage/givenchy-ange-noir.jpg"),
          marque: "GIVENCHY",
          titre: "L'Ange Noir",
          categorie: "Givenchy, stratégie digitale et création de contenu",
          video: "",
          lien: "/givenchy-angenoir",
          filtre:
            "GRAPHISME,COMMUNICATION,DIGITAL,MOTION DESIGN,TOURNAGE,CRÉATION,LUXE",
        },
        {
          image: require("../assets/images/homepage/ligue-contre-le-cancer.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Ligue Contre Le Cancer",
          categorie: "Spot TV",
          video: "video",
          lien: "ligue-contre-le-cancer",
          filtre: "GRAPHISME,ANIMATION,SPOT",
        },
        {
          image: require("../assets/images/homepage/guilty-tags.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Guilty Tags",
          categorie: "Spot TV",
          video: "video",
          lien: "guilty-tags",
          filtre: "GRAPHISME,MOTION DESIGN,ANIMATION,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/veranda-rideau.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Véranda rideau",
          categorie: "Spot TV",
          video: "video",
          lien: "veranda-rideau",
          filtre: "MOTION DESIGN,ANIMATION,2D,3D,BILLBOARD,",
        },
        {
          image: require("../assets/images/homepage/holiday-on-ice.jpg"),
          marque: "GERARD DROUOT",
          titre: "Holiday On Ice Believe",
          categorie: "Conception et réalisation spot TV - tournage",
          video: "video",
          lien: "holiday-on-ice",
          filtre: "TOURNAGE,CRÉATION,SPOT",
        },
        {
          image: require("../assets/images/homepage/rtl2_indochine.jpg"),
          marque: "SONY MUSIC",
          titre: "RTL2 Indochine",
          categorie: "Spot TV",
          video: "video",
          lien: "rtl2_indochine",
          filtre: "SPOT,TOURNAGE,ANIMATION",
        },
        {
          image: require("../assets/images/homepage/aldebert.jpg"),
          marque: "SONY MUSIC",
          titre: "Aldebert",
          categorie: "Spot TV",
          video: "video",
          lien: "aldebert",
          filtre: "SPOT,ANIMATION,2D",
        },
        {
          image: require("../assets/images/homepage/enedis.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Enedis",
          categorie: "Billboard",
          video: "video",
          lien: "enedis",
          filtre: "SPOT,ANIMATION,2D,BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/eminem-revival.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Eminem Revival",
          categorie: "Billboard",
          video: "video",
          lien: "eminem-revival",
          filtre: "SPOT,ANIMATION,2D,3D",
        },
        {
          image: require("../assets/images/homepage/telez.jpg"),
          marque: "VICTORIA",
          titre: "TéléZ",
          categorie: "Spot TV - Billboards TV",
          video: "video",
          lien: "telez",
          filtre: "SPOT,BILLBOARD,TV",
        },
        {
          image: require("../assets/images/homepage/celine-dion_encore-un-soir.jpg"),
          marque: "SONY MUSIC",
          titre: "Celine Dion - Encore Un Soir",
          categorie: "Spot TV",
          video: "video",
          lien: "celine-dion_encore-un-soir",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/maitre-gims.jpg"),
          marque: "SONY MUSIC",
          titre: "Maître Gims",
          categorie: "Spot TV",
          video: "video",
          lien: "maitre-gims",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/bruno-mars.jpg"),
          marque: "WARNER MUSIC",
          titre: "Bruno Mars",
          categorie: "Spot TV",
          video: "video",
          lien: "bruno-mars",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/julien-clerc.jpg"),
          marque: "WARNER MUSIC",
          titre: "Julien Clerc",
          categorie: "Spot TV",
          video: "video",
          lien: "julien-clerc",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/robbie-williams.jpg"),
          marque: "SONY MUSIC",
          titre: "Robbie Williams",
          categorie: "Spot TV",
          video: "video",
          lien: "robbie-williams",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/stax.jpg"),
          marque: "VICTORIA",
          titre: "Stax",
          categorie: "Spot TV",
          video: "video",
          lien: "stax",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/patrick-bruel-barbara.jpg"),
          marque: "SONY MUSIC",
          titre: "Patick Bruel chante Barbara",
          categorie: "Spot TV",
          video: "video",
          lien: "patrick-bruel-barbara",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/rolling-stones.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Rolling Stones",
          categorie: "Spot TV",
          video: "video",
          lien: "rolling-stones",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/jesus-spectacle-musical.jpg"),
          marque: "SONY MUSIC",
          titre: "Jésus, le spectacle musical",
          categorie: "Spot TV",
          video: "video",
          lien: "jesus-spectacle-musical",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/lp.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "LP",
          categorie: "Spot TV",
          video: "video",
          lien: "lp",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/freia.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Freia",
          categorie: "Spot TV",
          video: "video",
          lien: "freia",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/la-la-land.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "La La Land",
          categorie: "Spot TV",
          video: "video",
          lien: "la-la-land",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/katy-perry.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Katy Perry",
          categorie: "Spot TV",
          video: "video",
          lien: "katy-perry",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/shakira.jpg"),
          marque: "SONY MUSIC",
          titre: "Shakira",
          categorie: "Spot TV",
          video: "video",
          lien: "shakira",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/le-soldat-rose.jpg"),
          marque: "BMG",
          titre: "Le Soldat Rose, adultes",
          categorie: "Spot TV",
          video: "video",
          lien: "le-soldat-rose",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/keen-v.jpg"),
          marque: "WARNER MUSIC",
          titre: "Keen'V",
          categorie: "Spot TV",
          video: "video",
          lien: "keen-v",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/philippe_jaroussky.jpg"),
          marque: "WARNER MUSIC",
          titre: "Philippe Jaroussky",
          categorie: "Spot TV",
          video: "video",
          lien: "philippe_jaroussky",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/sam-smith.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Sam Smith",
          categorie: "Spot TV",
          video: "video",
          lien: "sam-smith",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/marina_kaye.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Marina Kaye",
          categorie: "Spot TV",
          video: "video",
          lien: "marina_kaye",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/amir.jpg"),
          marque: "WARNER MUSIC",
          titre: "Amir",
          categorie: "Spot TV",
          video: "video",
          lien: "amir",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/pink.jpg"),
          marque: "SONY MUSIC",
          titre: "Pink",
          categorie: "Spot TV",
          video: "video",
          lien: "pink",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/gauvain.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Gauvain",
          categorie: "Spot TV",
          video: "video",
          lien: "gauvain",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/etienne-daho.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Etienne Daho",
          categorie: "Spot TV",
          video: "video",
          lien: "etienne-daho",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/soprano.jpg"),
          marque: "WARNER MUSIC",
          titre: "Soprano",
          categorie: "Spot TV",
          video: "video",
          lien: "soprano",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/les-insus-live.jpg"),
          marque: "WARNER MUSIC",
          titre: "Les Insus Live",
          categorie: "Spot TV",
          video: "video",
          lien: "les-insus-live",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/orelsan.jpg"),
          marque: "WAGRAM MUSIC",
          titre: "Orelsan",
          categorie: "Spot TV",
          video: "video",
          lien: "orelsan",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/bigflo-et-oli.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Bigflo & Oli",
          categorie: "Spot TV",
          video: "video",
          lien: "bigflo-et-oli",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/eminem.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Eminem ",
          categorie: "Spot TV",
          video: "video",
          lien: "eminem",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/silence-on-tourne.jpg"),
          marque: "THÉATRE FONTAINE",
          titre: "Silence on tourne",
          categorie: "Spot TV",
          video: "video",
          lien: "silence-on-tourne",
          filtre: "SPOT,ENTERTAINMENT",
        },
        {
          image: require("../assets/images/homepage/renaud-capucon.jpg"),
          marque: "WARNER MUSIC",
          titre: "Renaud Capuçon",
          categorie: "Spot TV",
          video: "video",
          lien: "renaud-capucon",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/christine-and-the-queen.jpg"),
          marque: "BECAUSE MUSIC",
          titre: "Christine And The Queen",
          categorie: "Spot TV",
          video: "video",
          lien: "christine-and-the-queen",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/a-star-is-born.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "A Star Is Born",
          categorie: "Spot TV",
          video: "video",
          lien: "a-star-is-born",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/peau-d-ane.jpg"),
          marque: "THÉATRE MARIGNY",
          titre: "Peau d'Âne",
          categorie: "Spot TV",
          video: "video",
          lien: "peau-d-ane",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/holiday-on-ice-75-ans.jpg"),
          marque: "GERARD DROUOT",
          titre: "Holiday On Ice 75 ans",
          categorie: "Spot TV",
          video: "video",
          lien: "holiday-on-ice-75-ans",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/sting_my-songs.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Sting",
          categorie: "Spot TV",
          video: "video",
          lien: "sting_my-songs",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/elton-john.jpg"),
          marque: "GERARD DROUOT",
          titre: "Elton John",
          categorie: "Spot TV",
          video: "video",
          lien: "elton-john",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/rita_mitsouko.jpg"),
          marque: "BECAUSE MUSIC",
          titre: "Rita Mitsouko",
          categorie: "Spot TV",
          video: "video",
          lien: "rita_mitsouko",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/war_horse.jpg"),
          marque: "TS PROD",
          titre: "War Horse",
          categorie: "Spot TV",
          video: "video",
          lien: "war_horse",
          filtre: "SPOT,ENTERTAINMENT",
        },
        {
          image: require("../assets/images/homepage/paul_mccartney-freshen-up.jpg"),
          marque: "INTER CONCERTS",
          titre: "Paul McCartney",
          categorie: "Spot TV",
          video: "video",
          lien: "paul_mccartney-freshen-up",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/mont-blanc.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Mont Blanc",
          categorie: "Billboard",
          video: "video",
          lien: "mont-blanc",
          filtre: "BILLBOARD,TV",
        },
        {
          image: require("../assets/images/homepage/ademe.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Ademe",
          categorie: "Billboard",
          video: "video",
          lien: "ademe",
          filtre: "BILLBOARD,TV",
        },
        {
          image: require("../assets/images/homepage/norge.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Norge",
          categorie: "Billboard",
          video: "video",
          lien: "norge",
          filtre: "BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/monceau-fleurs.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "Monceau Fleurs",
          categorie: "Billboard",
          video: "video",
          lien: "monceau-fleurs",
          filtre: "BILLBOARD",
        },
        {
          image: require("../assets/images/homepage/depechmode.jpg"),
          marque: "RTL2",
          titre: "RTL2 Depeche Mode",
          categorie: "Spot Web",
          video: "video",
          lien: "depechmode",
          filtre: "SPOT,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/madonna_madamex.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Madonna Madame X",
          categorie: "Spot Web, Accroche multi réseaux",
          video: "video",
          lien: "madonna_madamex",
          filtre: "SPOT,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/givenchy_reveal.jpg"),
          marque: "GIVENCHY",
          titre: "Reveal",
          categorie: "Givenchy, stratégie digitale et création de contenu",
          video: "video",
          lien: "givenchy_reveal",
          filtre:
            "GRAPHISME,COMMUNICATION,DIGITAL,MOTION DESIGN,TOURNAGE,CRÉATION,LUXE",
        },
        {
          image: require("../assets/images/homepage/unis_cite.jpg"),
          marque: "UNIS CITÉS",
          titre: "Unis Cité",
          categorie: "Film Motion",
          video: "video",
          lien: "unis_cite",
          filtre: "GRAPHISME,MOTION DESIGN,CRÉATION,ANIMATION,2D",
        },
        {
          image: require("../assets/images/homepage/green_packaging.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Greenpackaging",
          categorie: "Film Motion",
          video: "video",
          lien: "green_packaging",
          filtre: "GRAPHISME,MOTION DESIGN,CRÉATION,ANIMATION,2D,3D",
        },
        {
          image: require("../assets/images/homepage/silence_ca_pousse.jpg"),
          marque: "FRANCE TLÉLÉVISIONS",
          titre: "Spot TV",
          categorie: "Film Motion",
          video: "video",
          lien: "silence_ca_pousse",
          filtre: "SPOT,GRAPHISME,ANIMATION,MAGAZINE,ÉDITION",
        },
        {
          image: require("../assets/images/homepage/culture_s_offre.jpg"),
          marque: "",
          titre: "La Culture S'offre",
          categorie: "Spot Digital",
          video: "video",
          lien: "culture_s_offre",
          filtre: "SPOT,GRAPHISME,MOTION DESIGN,CRÉATION,COMMUNICATION,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/cgr__sans_un_bruit.jpg"),
          marque: "CGR CINÉMAS",
          titre: "CGR Cinémas Sans Un Bruit 2",
          categorie: "Billboard",
          video: "video",
          lien: "cgr__sans_un_bruit",
          filtre: "BILLBOARD,TV,CINÉMA",
        },
        {
          image: require("../assets/images/homepage/lentilles_moins_cheres.jpg"),
          marque: "VICTORIA",
          titre: "Lentilles Moins Chères",
          categorie: "Billboard",
          video: "video",
          lien: "lentilles_moins_cheres",
          filtre: "BILLBOARD,TV",
        },
        {
          image: require("../assets/images/homepage/afnor.jpg"),
          marque: "FRANCE TÉLÉVISIONS",
          titre: "AFNOR",
          categorie: "Billboard",
          video: "video",
          lien: "afnor",
          filtre: "BILLBOARD,DIGITAL",
        },
        {
          image: require("../assets/images/homepage/saveurs_simplissime.jpg"),
          marque: "BURDA BLEU",
          titre: "Saveurs Simplissime",
          categorie: "Spot TV",
          video: "video",
          lien: "saveurs_simplissime",
          filtre: "SPOT,TV,MAGAZINE,ÉDITION",
        },
        {
          image: require("../assets/images/homepage/depeche_mode.jpg"),
          marque: "SONY MUSIC",
          titre: "Depeche Mode Live",
          categorie: "Spot TV",
          video: "video",
          lien: "depeche_mode",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/queen.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Queen",
          categorie: "Spot TV",
          video: "video",
          lien: "queen",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/gregory_lemarchal.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Grégory Lemarchal",
          categorie: "Spot TV",
          video: "video",
          lien: "gregory_lemarchal",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/rolling_stone.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Rolling Stones",
          categorie: "Spot TV",
          video: "video",
          lien: "rolling_stone",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/vitaa_slimane.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Vitaa & Slimane",
          categorie: "Spot TV",
          video: "video",
          lien: "vitaa_slimane",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/indochine_central.jpg"),
          marque: "PLAY TWO LIVE",
          titre: "Indochine Central",
          categorie: "Spot TV",
          video: "video",
          lien: "indochine_central",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/louane.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Louane",
          categorie: "Spot TV",
          video: "video",
          lien: "louane",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/gautier_capucon.jpg"),
          marque: "WARNER MUSIC",
          titre: "Gautier Capuçon",
          categorie: "Spot TV",
          video: "video",
          lien: "gautier_capucon",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/u2.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "U2",
          categorie: "Spot TV",
          video: "video",
          lien: "u2",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/johnny_reve_americain.jpg"),
          marque: "WARNER MUSIC",
          titre: "Johnny Rêve Américain",
          categorie: "Spot TV",
          video: "video",
          lien: "johnny_reve_americain",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/francis_cabrel.jpg"),
          marque: "SONY MUSIC",
          titre: "Francis Cabrel",
          categorie: "Spot TV",
          video: "video",
          lien: "francis_cabrel",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/melody_gardot.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Mélody Gardot",
          categorie: "Spot TV",
          video: "video",
          lien: "melody_gardot",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/master_kg.jpg"),
          marque: "WARNER MUSIC",
          titre: "Master KG",
          categorie: "Spot TV",
          video: "video",
          lien: "master_kg",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/calogero.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Calogero",
          categorie: "Spot TV",
          video: "video",
          lien: "calogero",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/bruce_springsteen.jpg"),
          marque: "SONY MUSIC",
          titre: "Bruce Springsteen",
          categorie: "Spot TV",
          video: "video",
          lien: "bruce_springsteen",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/acdc.jpg"),
          marque: "SONY MUSIC",
          titre: "AC/DC",
          categorie: "Spot TV",
          video: "video",
          lien: "acdc",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/jaroussky.jpg"),
          marque: "WARNER MUSIC",
          titre: "Jaroussky",
          categorie: "Spot TV",
          video: "video",
          lien: "jaroussky",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/eminem_web.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Eminem",
          categorie: "Spot TV",
          video: "video",
          lien: "eminem_web",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/gcm.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Grand Corps Malade",
          categorie: "Spot TV",
          video: "video",
          lien: "gcm",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/jane_birkin.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Jane Birkin",
          categorie: "Spot TV",
          video: "video",
          lien: "jane_birkin",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/benabar.jpg"),
          marque: "SONY MUSIC",
          titre: "Bénabar",
          categorie: "Spot TV",
          video: "video",
          lien: "benabar",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/rtl2_calogero.jpg"),
          marque: "RTL2",
          titre: "RTL2 Calogero",
          categorie: "Spot TV",
          video: "video",
          lien: "rtl2_calogero",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/ntm_live.jpg"),
          marque: "SONY MUSIC",
          titre: "NTM Live",
          categorie: "Spot TV",
          video: "video",
          lien: "ntm_live",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/celeste.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Céleste",
          categorie: "Spot TV",
          video: "video",
          lien: "celeste",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/gad_nougaro.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Gad Elmaleh - Nougaro",
          categorie: "Spot TV",
          video: "video",
          lien: "gad_nougaro",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/the_weeknd.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "The Weeknd",
          categorie: "Spot TV",
          video: "video",
          lien: "the_weeknd",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/gainsbourg.jpg"),
          marque: "UNIVERSAL MUSIC",
          titre: "Gainsbourg - L'album de sa vie",
          categorie: "Spot TV",
          video: "video",
          lien: "gainsbourg",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/flo_delavega.jpg"),
          marque: "WARNER MUSIC",
          titre: "Flo Delavega",
          categorie: "Spot TV",
          video: "video",
          lien: "flo_delavega",
          filtre: "SPOT",
        },
        {
          image: require("../assets/images/homepage/mustela_maternite.jpg"),
          marque: "",
          titre: "Mustella",
          categorie: "Film tutoriel",
          video: "video",
          lien: "mustela_maternite",
          filtre: "FILM TUTORIEL,VIDÉO TUTORIEL,TUTORIEL",
        },
      ],
      selectedCategory: "TOUT",
      searchOpen: false,
    };
  },
  components: {
    Search,
  },
  computed: {
    filteredContent: function() {
      let catergory = this.selectedCategory;

      if (catergory === "TOUT") {
        return this.people;
      } else {
        return this.people.filter(function(person) {
          // vérification de plusieurs tags de filtre
          const words = person.filtre.split(",");
          for (let i = 0; i < words.length; i++) {
            if (words[i] === catergory) return words[i] === catergory;
          }
          // return person.filtre === catergory;
        });
      }
    },
  },
  methods: {
    lien: function(event1, event2) {
      if (event1 !== "") {
        this.$root.$children[0].linkPlayer(event2);
      } else {
        console.log(event2);
        window.open(event2, "_self");
      }
      // console.log(event1+'   '+event2)
      // this.$root.$children[0].linkPlayer('rungis');
    },
    selectSearchCheck: function() {
      // on efface le contenu de recherche 'v-select' en simulant iun clic sur le bouton reset
      let el = document.querySelector(".resetVselect");
      if (el != null) {
        el.click();
      }
    },
    searchShowHide: function() {
      // apparition / disparition du bloc de recherche
      this.searchOpen = !this.searchOpen;
      if (this.searchOpen) {
        // apparition du bloc
        this.selectSearchCheck();
      } else {
        // on vérifie que le menu principal n'est pas sélectionné sinon on renvoie la catégorie "TOUT"
        let passMenu = true;
        for (let i = 0; i < this.menu.length; i++) {
          if (this.menu[i] === this.selectedCategory) {
            passMenu = false;
            break;
          }
        }
        if (passMenu) this.selectedCategory = "TOUT";
      }
    },
  },
};
</script>

<style scoped lang="scss">
h3,
p,
a {
  padding: 0;
  margin: 0;
}

$sobeautiful: #f50367;

/* filtre */
@media (max-width: 992px) {
  .filter label {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .filter {
    display: contents;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 0;

    label,
    #searchIcon {
      display: block;
      flex: 0 1 auto;
      list-style-type: none;
      padding: 2px 12px 2px 1em;
    }
  }
}

input {
  display: none;
}

#searchIcon {
  display: inline-block;

  &.searchOpen svg {
    fill: $sobeautiful;
  }
}

label,
#searchIcon {
  opacity: 0.7;
  cursor: pointer;
  padding: 2px 0 2px 1em;

  transition: width 2s;
  &:after {
    content: "";
    display: block;
    position: relative;
    // vertical-align: middle;
    width: 0;
    height: 2px;
    bottom: 6px;
    background: #b32572;
    transition: width 0.2s;
  }
}

input[type="radio"]:checked + label,
label:hover {
  opacity: 1;
  &:after {
    width: 100%;
  }
}

.people-list {
  min-height: 100vh;
}

/* Gallery */
/* grid */

/* Common style */
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  height: auto;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure h3,
.grid figure .task {
  margin: 0;
}

.grid figure .task {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/*---------------*/
/***** Ming *****/
/*---------------*/

figure.effect-ming {
  background: #101011;
}

figure.effect-ming img {
  opacity: 0.9;
  transition: opacity 0.35s;
}

figure.effect-ming figcaption::before {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  border: 2px solid #fff;
  box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2);
  content: "";
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: scale3d(1.4, 1.4, 1);
}

figure.effect-ming h2 {
  margin: 10% 0 10px 0;
  transition: transform 0.35s;
}

figure.effect-ming .task {
  padding: 0.5em;
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: scale(1.5);
}

figure.effect-ming:hover h2 {
  transform: scale(1);
}

figure.effect-ming:hover figcaption::before,
figure.effect-ming:hover .task {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

figure.effect-ming:hover figcaption {
  background-color: rgba(58, 52, 42, 0);
}

figure.effect-ming:hover img {
  opacity: 0.4;
}

// breakpoint
@include media-breakpoint-down(sm) {
  .grid figure figcaption {
    position: relative;
    height: auto;
    padding: 1em 1em 2em 1em;
  }
  .grid figure .task {
    opacity: 1;
    transform: scale(1) !important;
  }
  figure.effect-ming:hover figcaption::before {
    opacity: 0;
  }

  .grid figure img {
    min-height: auto;
  }
}

//////
.container {
  min-height: 1000px;
}

.text-32 {
  font-size: 1.8em;
  /*font-size: 32px;*/
}

h2 {
  opacity: 0.7;
  font-size: 11px;
  margin-top: 1rem;
}

h3 {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

i.playfairdisplay {
  font-size: 14px;
}
</style>
